import React, { Component } from 'react';
import { connect } from 'react-redux';
import Timer from 'react-compound-timer';
import OrderHistoryPopover from './OrderHistoryPopover';

const constants = require('./constants');

const STATE_ORDER_CANCELLED = constants.STATE_ORDER_CANCELLED;
const STATE_ORDER_CANCELLED_AFTER_COLLECTION = constants.STATE_ORDER_CANCELLED_AFTER_COLLECTION;
const STATE_ORDER_REJECTED = constants.STATE_ORDER_REJECTED;
const STATE_DELIVERY_COMPLETE = constants.STATE_DELIVERY_COMPLETE;

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
    <Timer {...timerProps}>
      {timerRenderProps =>
        <WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
    </Timer>
  );
  
class StateTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warn: false,
            show: true
        }
    }

    componentDidMount() {
        const { item } = this.props;
        const itemState = item.history[item.history.length-1];
        
        if(!itemState 
            || itemState.status === STATE_ORDER_CANCELLED
            || itemState.status === STATE_ORDER_CANCELLED_AFTER_COLLECTION
            || itemState.status === STATE_ORDER_REJECTED
            || itemState.status === STATE_DELIVERY_COMPLETE){
            this.setState({show: false});
        } else {
            this.startTimer();
        }
    }

    componentDidUpdate(prevProps){
        const { stop, setTime, setCheckpoints } = this.props.timer;
        const { item } = this.props;
        const prevItem = prevProps.item;
        const itemState = item.history[item.history.length-1];

        let prevItemState; 
        if(prevItem && prevItem.history.length > 0){
            prevItemState = prevItem.history[prevItem.history.length-1]; 
        }
        
        if(prevItemState.status !== itemState.status){
            if(!itemState 
                || itemState.status === STATE_ORDER_CANCELLED
                || itemState.status === STATE_ORDER_CANCELLED_AFTER_COLLECTION
                || itemState.status === STATE_ORDER_REJECTED
                || itemState.status === STATE_DELIVERY_COMPLETE){
                stop();
                this.setState({show: false});
            } else {
                const now = new Date();
                setTime(Math.max(0, now.getTime() - new Date(itemState.at).getTime()));
                if(itemState.warning_wait){
                    setCheckpoints([
                        {
                            time: itemState.warning_wait * 60 * 1000,
                            callback: () => this.setState({warn: true})
                        }
                    ]); 
                }
                //this.startTimer();
                if(this.state.warn) this.setState({warn: false});
            }
        } 
    }

    startTimer = () => {
        const { setCheckpoints, setTime, start} = this.props.timer;
        const { item } = this.props;
        const itemState = item.history[item.history.length-1];
        let timeToWarn;
        const now = new Date();

        if(itemState.warning_wait){
            timeToWarn = Math.max(0, 
                new Date(itemState.at).getTime() + 
                itemState.warning_wait * 60 * 1000 - now.getTime());
        }

        setTime(Math.max(0, now.getTime() - new Date(itemState.at).getTime()));
        start();

        if(itemState.warning_wait){
            if(timeToWarn === 0){
                this.setState({warn: true});
            } else {
                setCheckpoints([
                    {
                        time: itemState.warning_wait * 60 * 1000,
                        callback: () => this.setState({warn: true})
                    }
                ]);
            }
        }
    }
    
    render() { 
        const { item } = this.props;
        return (
            <>
                <span 
                    id={`timerToggler${item._id}`} 
                    style={
                        !this.state.show ? 
                        {display: 'none'} : 
                            this.state.warn ? 
                            {color: 'red'} : 
                            {}
                    }
                >
                    <Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)}`}/>
                    :<Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`}/>
                </span>
                {
                    !this.props.inPopover ?
                    <OrderHistoryPopover targetElement={`timerToggler${item._id}`} item={item} /> :
                    ""
                }
                
            </>
        );
    }
}

const TimerHOC = withTimer({
    lastUnit:"m",
    startImmediately: false
})(StateTimer);

const mapStateToProps = state => ({
    globalState: state.item
});

export default connect(mapStateToProps, {  })(TimerHOC);