import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    CustomInput
} from 'reactstrap';

import { connect } from 'react-redux';
import { saveSettings } from '../actions/itemActions';
import { NotificationManager } from 'react-notifications';

class SettingsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            hopscotch_id: '',
            loading: false,
            showAllOrders: this.props.globalState.settings ? !!this.props.globalState.settings.showAllOrders : false
        }
    }

    callback = (success, failureStatusCode) => {
        this.setState({ loading: false });
        this.toggle();
       
        if(success){
            NotificationManager.success('Settings successfully updated.');
        } else {
            NotificationManager.error(failureStatusCode ? 
                'The identifier you entered is invalid.': 
                'Error updating settings.');
        }
        
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            // Use the settings in the application state, so that if the user closes the model without
            // updating the settings, the values don't change
           // hopscotch_id: this.props.globalState.settings.hopscotch_id
        });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeCheckbox = e => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    onSubmit = e => {
        e.preventDefault();
        this.setState({"loading": true});  
        
        const settings = {
            hopscotch_id: this.state.hopscotch_id,
            showAllOrders: this.state.showAllOrders
            
        }

        // Save settings
        this.props.saveSettings(settings, this.callback);   
        //this.setState({"loading": true}); 
    }

    render(){
        return (
            <div>
                <NavLink style={{color: 'white', cursor: 'pointer'}} onClick={this.toggle}>Settings</NavLink>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Mission Control Settings</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label for="hopscotch_id">Hopscotch Identifier</Label>
                                <Input 
                                    type="text"
                                    name="hopscotch_id"
                                    id="hopscotch_id"
                                    placeholder="Enter Hopscotch identifier"
                                    style={{marginBottom: '1rem'}}
                                    value={this.state.hopscotch_id}
                                    //defaultValue={this.props.globalState.settings.hopscotch_id}
                                    onChange={this.onChange}
                                />
                                <br />
                                <CustomInput 
                                    type="checkbox" 
                                    id="showAllOrders"
                                    name="showAllOrders" 
                                    label="Show cancelled, rejected and completed orders?" 
                                    onChange={this.onChangeCheckbox}
                                    checked={this.state.showAllOrders}
                                    inline />
                                <Button 
                                    loading={this.state.loading} 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}}
                                    //disabled={!this.state.hopscotch_id} 
                                    block>
                                    Update settings
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { saveSettings })(SettingsModal);