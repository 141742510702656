import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import { connect } from 'react-redux';
import { switchRestaurant } from '../actions/itemActions';

const constants = require('./constants');

const ORDER_STATUS_REJECTED = constants.ORDER_STATUS_REJECTED;
const ORDER_STATUS_CANCELLED = constants.ORDER_STATUS_CANCELLED;
const ORDER_STATUS_CANCELLED_AFTER_COLLECTION = constants.ORDER_STATUS_CANCELLED_AFTER_COLLECTION;
const ORDER_STATUS_PENDING = constants.ORDER_STATUS_PENDING;
const ORDER_STATUS_BEING_AMENDED = constants.ORDER_STATUS_BEING_AMENDED;

const TASK_STATUS_SUCCESSFUL = constants.TASK_STATUS_SUCCESSFUL;

class SwitchRestaurantModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            to: ""
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            to: ""
        });
    }

    onChange = e => {
        this.setState({ to: e.target.value });
    }

    callback = success  => {
        this.setState({ loading: false });
        this.toggle();
        if(success){
            NotificationManager.success('Restaurant switched!');
        } else {
            NotificationManager.error('There was a problem switching the restaurant.');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const { item } = this.props;
        
        let switchObj = {}; 
        switchObj.item_id = item._id;
        switchObj.from = item.order.restaurants[0].name;
        switchObj.to = this.state.to;
        switchObj.at = new Date();

        this.props.switchRestaurant(switchObj, this.callback);
        this.setState({ loading: true });
    }

    render(){
        const { item } = this.props;
        return (
            <>
                {
                    <Button className="btn-hopscotch" style={{width: '100%'}}
                        disabled={
                            item.sister_restaurants.length === 0 ||
                            !!item.deleted || 
                            (item.order.state === ORDER_STATUS_PENDING &&
                                (!!item.cancelled || 
                                !!item.cancelled_after_collection || 
                                !!item.rejected)) ||
                            item.order.state === ORDER_STATUS_CANCELLED ||
                            item.order.state === ORDER_STATUS_CANCELLED_AFTER_COLLECTION ||
                            item.order.state === ORDER_STATUS_REJECTED ||
                            (!!item.pickup_task 
                                && item.pickup_task.job_status === TASK_STATUS_SUCCESSFUL) || 
                            item.order.state === ORDER_STATUS_BEING_AMENDED ||
                            (item.order.first_time_customer && !item.first_time_customer_verified)
                        }
                        onClick={this.toggle}>
                            Switch
                    </Button>
                }
                
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Switch Restaurant</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <UncontrolledDropdown style={{marginBottom: '0.5rem'}}>
                                    <DropdownToggle caret 
                                        className="btn-hopscotch"                                         
                                    > {
                                        this.state.to || "Please choose a restaurant"
                                   } 
                                    </DropdownToggle>
                                    <DropdownMenu>{
                                        item.sister_restaurants.map(name => 
                                            <DropdownItem key={name} onClick={this.onChange} value={name}>{name}</DropdownItem>
                                        )
                                    }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                                                
                                <Button 
                                    loading={this.state.loading}  
                                    disabled={
                                        !this.state.to ||
                                        item.sister_restaurants.length === 0 ||
                                        !!item.deleted || 
                                        (item.order.state === ORDER_STATUS_PENDING &&
                                            (!!item.cancelled || 
                                            !!item.cancelled_after_collection || 
                                            !!item.rejected)) ||
                                        item.order.state === ORDER_STATUS_CANCELLED ||
                                        item.order.state === ORDER_STATUS_CANCELLED_AFTER_COLLECTION ||
                                        item.order.state === ORDER_STATUS_REJECTED ||
                                        (!!item.pickup_task 
                                            && item.pickup_task.job_status === TASK_STATUS_SUCCESSFUL) || 
                                        item.order.state === ORDER_STATUS_BEING_AMENDED ||
                                        (item.order.first_time_customer && !item.first_time_customer_verified)
                                    }
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    block
                                >
                                    Switch Restaurant
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalSettings: state.item
});
export default connect(mapStateToProps, { switchRestaurant })(SwitchRestaurantModal);