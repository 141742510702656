import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Moment from 'react-moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { connect } from 'react-redux';
import { sendEmail } from '../actions/itemActions';

class EmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            to: props.email ? props.email.to : props.link || props.to,
            bcc: props.email ? props.email.bcc : "",
            subject: props.email ? props.email.subject : "",
            html: props.email ? props.email.html : "",
            template_subject: ""
        }
    }

    EMAIL_TEMPLATES = {
        "CANT_REACH_CUSTOMER": {
            subject: "Hopscotch can't reach you",
            html: `<p>Hi ${this.props.item.order.first_name},</p>
            <p><br></p><p>We haven't been able to reach you on the 
            phone number${this.props.item.order.phone_number_2?'s':''} you provided:</p>
            <p><br></p>
            <p>${parsePhoneNumberFromString(this.props.item.order.phone_number).formatInternational()}</p>
            ${this.props.item.order.phone_number_2 ? 
                "<p>"+parsePhoneNumberFromString(this.props.item.order.phone_number_2).formatInternational()+"</p>" : 
                ""}
            <p><br></p>
            <p>Please respond to this email with a phone number at which we can reach you, either by 
            phone call or via WhatsApp.</p>
            <p><br></p>
            <p><strong>We can't proceed with your order until we make contact.</strong></p>
            <p><br></p>
            <p>Hopscotch Team</p>`
        }, "PROBLEM_WITH_ORDER": {
            subject: `There's a problem with your order`,
            html: `<p>Hi ${this.props.item.order.first_name},</p>
            <p><br></p><p>Unfortunately, ${this.props.item.order.restaurants[0].name} has no more XXX. They have YYY and ZZZ.</p>
            <p><br></p>
            <p>How should we proceed with your order?</p>
            <p><br></p>
            <p>Hopscotch Team</p>`
        }, "LONG_WAIT": {
            subject: `Your order: Estimated wait time is over an hour`,
            html: `<p>Hi ${this.props.item.order.first_name},</p>
            <p><br></p><p>We're quite busy at the moment, so we just wanted to let you know that it may be 
            another hour before you receive your food. </p>
            <p><br></p>
            <p>Apologies in advance.</p>
            <p><br></p>
            <p>Hopscotch Team</p>`
        }, "FOOD_NOT_READY": {
            subject: `Update: Your order will take another 30 minutes to prepare`,
            html: `<p>Hi ${this.props.item.order.first_name},</p>
            <p><br></p><p>${this.props.item.order.restaurants[0].name} is quite busy, and has just informed us 
            that your order will take a further 30 minutes to prepare. We will collect it at 
            that point.</p>
            <p><br></p>
            <p>Apologies for the delay.</p>
            <p><br></p>
            <p>Hopscotch Team</p>`
        }, "FOOD_NOT_READY_DRIVER_ARRIVED": {
            subject: `Update: The driver continues to wait on the food`,
            html: `<p>Hi ${this.props.item.order.first_name},</p>
            <p><br></p><p>Just letting you know that our driver is still waiting at 
            ${this.props.item.order.restaurants[0].name} for your order. The food is not yet ready. They should be on their way to you
            shortly. </p>
            <p><br></p>
            <p>Hopscotch Team</p>`
        },  "CANT_REACH_RESTAURANT": {
            subject: `Your order: We can't reach the restaurant`,
            html: `<p>Hi ${this.props.item.order.first_name},</p>
            <p><br></p><p>We haven't been able to make reach 
            ${this.props.item.order.restaurants[0].name}, so we've had to cancel your order. Sorry about that.</p>
            ${this.props.item.order.payment_type === 2 ? 
                `<p><br></p><p>Your card hasn't been charged, but there is a hold on your funds
                that will be removed shortly. The timiing depends on your bank, but this typically 
                happens within a few business days.</p>`:``}
            <p><br></p>
            <p>Hopscotch Team</p>`
        }, "BLANK_EMAIL": {
            subject: ``,
            html: `<p>Hi ${this.props.item.order.first_name},</p><p><br></p>
                   <p>Hopscotch Team</p>`
        }
    };

    toggle = e => {
        if(e) e.preventDefault();
        this.setState({
            modal: !this.state.modal,
        });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSetTemplate = e => {
        let template = e.target.value;
        this.setState({ 
            subject: this.EMAIL_TEMPLATES[template].subject,
            template_subject: e.target.textContent,
            html: this.EMAIL_TEMPLATES[template].html
        });
    }

    onBodyChange = html => {
        this.setState({ html });
    }

    isToday = someDate => {
        let today = new Date(); 
        return today.getFullYear() === someDate.getFullYear() 
        && today.getMonth() === someDate.getMonth()
        && today.getDate() === someDate.getDate();
    }

    callback = success  => {
        this.setState({ loading: false });
        this.toggle();
        if(success){
            NotificationManager.success('Email sent!');
        } else {
            NotificationManager.error('There was a problem sending the email.');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        
        let emailObj = {}; 
        emailObj.item_id = this.props.item._id;
        emailObj.email = {};
        emailObj.email.to = this.state.to;
        if(this.state.bcc){
            emailObj.email.bcc = this.state.bcc;
        }
        emailObj.email.subject = this.state.subject;
        emailObj.email.html = this.state.html;
        emailObj.email.sent = new Date();

        this.props.sendEmail(emailObj, this.callback);
        this.setState({ loading: true });
    }

    render(){
        const { email, link } = this.props;
        return (
            <>
                {
                    email ? 
                    <div>
                        <span>({this.isToday(new Date(email.sent)) ? 
                                <Moment format="h:mma" date={email.sent} local />:
                                <Moment format="MMM D, h:mma" date={email.sent} local />}) </span>
                        <a href="# " onClick={this.toggle}>{email.subject}</a> 
                    </div> : 
                    link ?
                    <a href="# " onClick={this.toggle}>{link}</a>                    
                    :
                    <Button className="btn-hopscotch" style={{width: '100%', marginBottom: '0.5rem'}}
                        onClick={this.toggle}>
                            New
                    </Button>
                }
                
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>{email ? <span>Sent  at {this.isToday(new Date(email.sent)) ? <Moment format="h:mma" date={email.sent} local />:<Moment format="MMM D, h:mma" date={email.sent} local />}</span> : "Email the Customer"}</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <UncontrolledDropdown style={{marginBottom: '0.5rem'}}>
                                    <DropdownToggle caret 
                                        className="btn-hopscotch" 
                                        style={email ? {display: 'none'}:{marginLeft: '5rem'}}
                                    >
                                        {
                                            this.state.template_subject || "Please choose an email template"
                                       } 
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={this.onSetTemplate} value="CANT_REACH_CUSTOMER">Hopscotch can't reach you</DropdownItem>
                                        <DropdownItem onClick={this.onSetTemplate} value="PROBLEM_WITH_ORDER">Problem with ordered items</DropdownItem>
                                        <DropdownItem onClick={this.onSetTemplate} value="LONG_WAIT">Hopscotch is busy; the wait time may be long</DropdownItem>
                                        <DropdownItem onClick={this.onSetTemplate} value="FOOD_NOT_READY">Update: The food will take another 30 minutes</DropdownItem>
                                        <DropdownItem onClick={this.onSetTemplate} value="FOOD_NOT_READY_DRIVER_ARRIVED">Update: The driver continues to wait on the food</DropdownItem>
                                        <DropdownItem onClick={this.onSetTemplate} value="CANT_REACH_RESTAURANT">Hopscotch can't reach the restaurant</DropdownItem>
                                        <DropdownItem onClick={this.onSetTemplate} value="BLANK_EMAIL">Blank email</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                
                                <span style={{marginRight: '5%'}}>To&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                    <Input 
                                        type="text" 
                                        name="to" 
                                        id="to" 
                                        value={this.state.to}
                                        onChange={this.onChange}
                                        style={{width: '87%', display: 'inline', marginBottom: '0.5rem'}}
                                        disabled={!!email}
                                        required 
                                    />
                                <span style={{marginRight: '3%'}}>BCC&nbsp;&nbsp;&nbsp;</span> 
                                <Input 
                                    type="text" 
                                    name="bcc" 
                                    id="bcc" 
                                    value={this.state.bcc}
                                    onChange={this.onChange}
                                    style={{width: '87%', display: 'inline', marginBottom: '0.5rem'}}
                                    disabled={!!email}
                                />
                                <span>Subject&nbsp;</span> 
                                <Input 
                                    type="text" 
                                    name="subject" 
                                    id="subject" 
                                    value={this.state.subject}
                                    onChange={this.onChange}
                                    style={{width: '87%', display: 'inline', marginBottom: '0.5rem'}}
                                    disabled={!!email}
                                    required
                                />
                                <ReactQuill 
                                    value={this.state.html}
                                    onChange={this.onBodyChange} 
                                    readOnly={!!email}
                                    placeholder="Type the email body here..."
                                />
                                
                                <Button 
                                    loading={this.state.loading} 
                                    disabled={
                                        !this.state.to || 
                                        !this.state.subject ||
                                        !this.state.html 
                                    } 
                                    className="btn-hopscotch" 
                                    style={email ? {display: 'none'} : { marginTop: '2rem'}} 
                                    block
                                >
                                    Send
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalSettings: state.item
});
export default connect(mapStateToProps, { sendEmail })(EmailModal);