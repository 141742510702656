import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import { Button, Badge, UncontrolledTooltip } from 'reactstrap';
import ItemDetails from './ItemDetails';
import StatusBadge from './StatusBadge';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Moment from 'react-moment';
import StateTimer from './StateTimer';
import DeliveryTimer from './DeliveryTimer';
import ClipLoader from 'react-spinners/ClipLoader';
//import paginationFactory from 'react-bootstrap-table2-paginator';
//import overlayFactory from 'react-bootstrap-table2-overlay';
import filterFactory, { textFilter, selectFilter /*, multiSelectFilter */} from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Truncate from 'react-truncate';

const utils = require('../utils/ItemUtils');
const constants = require('./constants');
const moment = require('moment');
const STATE_DELIVERY_COMPLETE = constants.STATE_DELIVERY_COMPLETE;
const STATE_DELIVERY_ARRIVED = constants.STATE_DELIVERY_ARRIVED;
const ORDER_STATUS_IN_PROGRESS = constants.ORDER_STATUS_IN_PROGRESS;

const CONDENSED_STATE_IN_PROGRESS = constants.CONDENSED_STATE_IN_PROGRESS;
const CONDENSED_STATE_NOT_IN_PROGRESS = constants.CONDENSED_STATE_NOT_IN_PROGRESS;
const CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION = constants.CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION;

class ItemList extends Component {
   
    componentDidUpdate(prevProps){

        let newMessageBadge;
        for(const item of this.props.globalState.items){
            newMessageBadge = document.getElementById(["NewMessageBadge"+item._id]);
            
            if(newMessageBadge){
                if(this.showNewMessageBadge(item)){
                    newMessageBadge.style.display = 'inline';
                } else {
                    newMessageBadge.style.display = 'none';
                }
            }    
            
        }
    }

    onDeleteClick = (id, e) => {   
        this.props.deleteItem(id);
        e.stopPropagation(); // If the item is being deleted, don't call the onView handler
    }

    showNewMessageBadge = item => {  
        return (
                    !!item && item.messages.length > 0 
                    && !!item.messages.find(m => m.sender !== "Hopscotch" && !m.readByHopscotch)
                );
    }
    
    nameFormatter = (cell, item) => {
        return (
            <>
                <Truncate id={"ID"+item._id+"CustomerName"} width={165}>
                    {item.order.first_name + ' ' + item.order.last_name}
                </Truncate>
               {/* <UncontrolledTooltip placement="right" target={"ID"+item._id+"CustomerName"}>
                {item.order.first_name + ' ' + item.order.last_name}
                </UncontrolledTooltip> */}
            </>
        );
      }

      restaurantNameFormatter = (cell, item) => {   
        let info = item.fully_kiosk_device_info;
        let title, online, battery, plugged, text_colour;
        if(info){
            online = parseInt(info.lastHeartbeatAge) < 90 ? true : false;
            title = `Tablet is ${online ? "online. " : "offline. "}`;
            let lhinfo = info.lastHeartbeatInfo;
            if(lhinfo && online){
                battery = lhinfo.batteryLevel;
                plugged = lhinfo.isPlugged;
                title += `Battery: ${battery}% (${plugged?"plugged":"unplugged"}). `;
            }

            if(info.startURLReloadTime){
                title += `Attempted to reload tablet app at `;
                title += `${moment(new Date(info.startURLReloadTime)).format("h:mma")}: `;
                title += `${info.startURLReloadSuccess ? "Success" : "FAILED"}`
            }
        }

        if(info){
            if(!online){
                text_colour = "red";
            } else if (battery < 20 && !plugged){
                text_colour = "orange";
            }
        }
        return (
            <>
                <Truncate 
                    style={{color: text_colour}} 
                    id={"ID"+item._id+"RestaurantName"} 
                    width={165}>
                    {cell}
                </Truncate>
                {
                    <UncontrolledTooltip placement="right" target={"ID"+item._id+"RestaurantName"}>
                        {cell + (title ? ". " + title : "")}
                    </UncontrolledTooltip> 
                }                
                
            </>
        );
    }

      driverFormatter = (cell, item) => {
        return (
            <>
                <Truncate id={"ID"+item._id+"Driver"} width={90}>{cell}</Truncate>
                <UncontrolledTooltip placement="right" target={"ID"+item._id+"Driver"}>
                {cell}
                </UncontrolledTooltip>
            </>
        );
      }

      
    
    deleteButtonFormatter = () => {
        return (
        <Button
            className="remove-btn"
            color="danger"
            size="sm"
        >&times;</Button>
        );
    }

    dateFormatter = cell => {
        return cell ? <Moment format="h:mma" date={cell} local /> : "";
    }

    datetimeFormatter = cell => {
        if(!cell) return "";

        let datetime = new Date(cell);
        if(this.isToday(datetime)){
            return <Moment format="h:mma" date={datetime} local />;
        } else {
            return <Moment format="MMM D, h:mma" date={datetime} local />
        }        
    }

    isToday(someDate){
        let today = new Date(); 
        return today.getFullYear() === someDate.getFullYear() 
        && today.getMonth() === someDate.getMonth()
        && today.getDate() === someDate.getDate();
    }


    orderStateFormatter = (cell, item) => <StatusBadge item={item} />
        

    arrivalTimeFormatter = (cell, item) => {
       
        return (
            <span>{item && item.pickup_task && item.pickup_task.started_datetime && item.distance_to_restaurant ? 
                <Moment format="h:mma" 
                add={
                    {
                        "hours": item.distance_to_restaurant / 28968, // Assumes driving speed of 18 mph
                        "minutes": 5 
                    }
                } local>{item.pickup_task.started_datetime}</Moment>
                    :
                    ""
                }</span>
        );
    }

    readyByFormatter = (cell, item) => {
       
        return (
            <span>{item && item.preparation_time && 
                    (item.accepted || item.order.state === ORDER_STATUS_IN_PROGRESS)? 
                (item.preparation_time > 0 ? 
                <Moment format="h:mma" 
                add={
                    {
                        "minutes": item.preparation_time 
                    }
                } local>{new Date(item.accepted || item.order.accept_date)}</Moment>
                    :
                    "Pre-order")
                : ""
                }</span>
        );
    }

    messagesFormatter = (cell, item) => {
        const showBadge = this.showNewMessageBadge(item);
        return <Badge 
                id={"NewMessageBadge"+item._id} 
                color="danger" 
                style={showBadge ? { display: 'inline'} : { display: 'none'}} 
                pill>Message</Badge>;
    }

    addressFormatter = (cell, item) => {
        const { order } = item;

        if(order && order.delivery_tasks[0] && order.delivery_tasks[0].method === "pickup"){
            return <span style={{color: 'red'}}>(Pickup)</span>;
        }
        const address =  [
                            order.address_line_1, 
                            order.address_line_2, 
                            order.parish
                        ].join(", ").replace(", , ", ", ");  
        return (
            <>
                <Truncate id={"ID"+item._id+"Address"} width={165}>{address}</Truncate>
                {/*<UncontrolledTooltip placement="right" target={"ID"+item._id+"Address"}>
                        {address}
                    </UncontrolledTooltip>*/}
            </>
        );
    }

    //deliveryCostFormatter = cell => "$"+cell

    timeInStatusFormatter = (cell, item) => {

     //   return "";
     if(!item || !item.order || !item.order.delivery_tasks[0]){
         return "";
     }
        return item.order.delivery_tasks[0].delivery_time_to ? "" :
        (
            <StateTimer inPopover={false} item={item}/>
        );    
    }

    totalTimeFormatter = (cell, item) => {
     //   return "";       
        if(item && item.order && item.order.delivery_tasks[0] && item.order.delivery_tasks[0].order_type==="pre-order"){
            let arrival_time = utils.getStatusTime(item, STATE_DELIVERY_ARRIVED);
            if(!arrival_time){
                return "";
            }
           
            return (<Moment 
                format={this.isToday(arrival_time) ? "h:mma" : "MMM D, h:mma"} 
                date={arrival_time} local />);
            
        }
        return (
            <DeliveryTimer inPopover={false} item={item}/>
        );    
    }

    deliveryEstimateFormatter = (dt, item) => {
        if(item && item.order && item.order.delivery_tasks[0] && item.order.delivery_tasks[0].order_type==="pre-order"){
            const delivery_task = item.order.delivery_tasks[0];
            const delivery_time = new Date(delivery_task.delivery_time_to);
            if(this.isToday(delivery_time)){
                if(delivery_task.pre_order_time==='range'){
                    return "Today";
                }
                return (<Moment 
                    format="h:mma" 
                    date={delivery_time} local />);
            }
            return (<Moment 
                format={delivery_task.pre_order_time==='range' ? "MMM D" : "MMM D, h:mma"} 
                date={delivery_time} local />);
        }
        const estimate = utils.getDeliveryEstimateMins(item);
        return (estimate[0] && estimate[1]) ? utils.getDeliveryEstimateMins(item).join(" - ") : "";
    }

    onTimeFormatterTextOnly = (cell, item) => {
        return this.onTimeFormatter(cell, item, true);
    }

    onTimeFormatter = (cell, item, textOnly) => {
        const itemState = item.history[item.history.length-1];
        let delivery_task = item.order.delivery_tasks[0];

        if(!delivery_task){
            return "";
        }
        let badgeText, badgeColor, arrivalTime;

        try {
            arrivalTime = utils.getStatusTime(item, STATE_DELIVERY_ARRIVED).getTime();
        } catch (err){
            return "";
        }

        if(itemState.status !== STATE_DELIVERY_ARRIVED && 
            itemState.status !== STATE_DELIVERY_COMPLETE){
            return "";
        }

        if(delivery_task.order_type==="pre-order"){
            let expected_arrival;

            if(delivery_task.pre_order_time === "range"){
                expected_arrival = moment(new Date(delivery_task.delivery_time_to)).endOf('day').toDate();
            } else {
                expected_arrival = new Date(delivery_task.delivery_time_to)   
            }

            if(arrivalTime - expected_arrival.getTime() > 0){
                badgeText = "Late";
                badgeColor = "danger";
            } else {
                badgeText = "On time";
                badgeColor = "success";   
            }
            
        } else {
            const deliveryEstimate = utils.getDeliveryEstimateMins(item);

            if(!deliveryEstimate[1]){
                return "";
            }

            const lowerDeliveryEstimate = !deliveryEstimate[0] ? null : deliveryEstimate[0] * 60 * 1000;
            const upperDeliveryEstimate = deliveryEstimate[1] * 60 * 1000;
            let deliveryTime;
            try{
                deliveryTime = arrivalTime - 
                new Date(item.order.order_date).getTime();
            } catch (err){
                return "";
            }
            
            if(lowerDeliveryEstimate){
                if(deliveryTime < lowerDeliveryEstimate){
                    badgeText = "Early";
                    badgeColor = "success";
                } else if (deliveryTime >= lowerDeliveryEstimate && deliveryTime <= upperDeliveryEstimate){
                    badgeText = "On time";
                    badgeColor = "success";
                } else {
                    badgeText = "Late";
                    badgeColor = "danger";
                }
            } else {
                if(deliveryTime <= upperDeliveryEstimate){
                    badgeText = "On time";
                    badgeColor = "success";
                } else {
                    badgeText = "Late";
                    badgeColor = "danger";
                }
            }
        }

        return textOnly === true ? 
                badgeText :
                <Badge color={badgeColor} pill>{badgeText}</Badge>;   
    }

    styledTextFilter = textFilter({
        className: 'filter-hopscotch',
        placeholder: 'Search...'

    });

    render(){
        const { items, loading } = this.props.globalState;
        const timingOptions = {
            'Early': 'Early',
            'On time': 'On time',
            'Late': 'Late'
        };

        const condensedStateOptions = {};
            
        condensedStateOptions[CONDENSED_STATE_IN_PROGRESS] = "In Progress";
        condensedStateOptions[CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION] = "Attention";
        condensedStateOptions[CONDENSED_STATE_NOT_IN_PROGRESS] = "Done";
          
        const columns = [
            /*{
                dataField: 'deleteButton',
                text: '',
                formatter: this.deleteButtonFormatter,
                events: {
                    onClick: (e, column, columnIndex, item, rowIndex) => {
                        this.onDeleteClick(item._id, e);
                    }
                },
                isDummyField: true,
                //headerStyle: { "width": '3rem'}
            }, */
            {
                dataField: 'order.order_date',
                text: 'Time',
                formatter: this.datetimeFormatter,
                sort: true                
            }, 
            {
                dataField: 'order.customer',
                text: 'Customer',
                formatter: this.nameFormatter,
                isDummyField: true,
                filter: this.styledTextFilter, 
                filterValue: (cell, item) => item.order.first_name + ' ' + item.order.last_name,
                headerStyle: { "width": '11rem'}
            },
            {
                dataField: 'order.address',
                text: 'Address',
                formatter: this.addressFormatter,
                isDummyField: true,
                headerStyle: { "width": '11rem'}
            },
            {
                dataField: 'order.restaurants[0].name',
                text: 'Restaurant',
                formatter: this.restaurantNameFormatter,
                filter: this.styledTextFilter,
                headerStyle: { "width": '11rem'}
            },
            {
                dataField: 'order.state',
                text: 'Status',
                formatter: this.orderStateFormatter,
                /*filter: multiSelectFilter({
                    className: "multi-select-hopscotch",
                    options: condensedStateOptions,
                    defaultValue: [
                        CONDENSED_STATE_IN_PROGRESS, 
                        CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION, 
                        CONDENSED_STATE_NOT_IN_PROGRESS
                    ],
                    onFilter: filterValue =>  
                        this.props.globalState.items.filter(item =>     
                            filterValue.some(v => utils.getCondensedItemState(item).includes(v) )
                        ),
                    withoutEmptyOption: true
                }),*/
                isDummyField: true,
                headerStyle: { "width": '8rem'}
            },
            {
                dataField: 'pickup_task.fleet_name',
                text: 'Driver',
                formatter: this.driverFormatter,
                headerStyle: { "width": '6rem'},
                sort: true,
                filter: this.styledTextFilter, 
            },
            {
                dataField: 'Time in Status',
                text: 'Time in Status',
                formatter: this.timeInStatusFormatter,
                isDummyField: true
            },
            {
                dataField: 'Total Time',
                text: 'Delivery Time',
                formatter: this.totalTimeFormatter,
                isDummyField: true
            },
            {
                dataField: 'order.delivery_tasks[0]',
                text: 'Estimate (mins)',
                formatter: this.deliveryEstimateFormatter,
                headerStyle: { "width": '7rem'}
            },
            {
                dataField: 'On Time?',
                text: 'Timing',
                formatter: this.onTimeFormatter,
                filterValue: this.onTimeFormatterTextOnly,
                filter: selectFilter({
                    options: timingOptions,
                    className: 'filter-hopscotch',
                    placeholder: 'Select'
                  }),
                headerStyle: { "width": '7rem'},
                isDummyField: true
            },
            {
                dataField: 'messages',
                text: '',
                formatter: this.messagesFormatter,
                isDummyField: true
            }
        ];

        const expandRow = {
            renderer: item => <ItemDetails item={item} />
        };

        const rowClasses = (row, rowIndex) => {
            if(row && row.order){
                setTimeout(() => {
                    let selectedRow = document.querySelector(`#data_table tbody tr.item${row._id}`);
                    if(selectedRow && !selectedRow.className.includes("fade-enter-active")){
                        selectedRow.className+=" fade-enter-active";
                    }
                }, 1000);
                return `item${row._id} fade-enter`;
            } else {
                return "";
            }
            
        };
          
        
        /*const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
          }) => {
            const handleClick = (e) => {
              e.preventDefault();
              onPageChange(page);
            };
            const activeStyle = {
                padding: '0.5rem 0.75rem',
                borderRadius: '0.25rem',
                textDecoration: 'none'
            };
            if (active) {
              activeStyle.backgroundColor = '#a32273';
              activeStyle.color = 'white';
            } else {
              activeStyle.backgroundColor = '#8f1061';
              activeStyle.color = 'black';
            }
            if (typeof page === 'string') {
              activeStyle.backgroundColor = 'white';
              activeStyle.color = '#a32273';
            }
            return (
              <li className="page-item">
                <a href="#" onClick={ handleClick } style={ activeStyle }>{ page }</a>
              </li>
            );
          };*/
          const NoDataIndication = () => 
                loading ? 
                 <div>
                    <ClipLoader
                        color={'#a32273'}
                        loading={true}
                    />
                </div> :
                <span>No orders yet for the day.</span>
          ;

        
        return ( 
                 
            <BootstrapTable 
                bootstrap4
                keyField="_id" 
                id="data_table"
                data={ items } 
                columns={ columns } 
                expandRow={ expandRow }
                bordered={ false }
                rowClasses={ rowClasses }
                //noDataIndication="No orders yet for the day."
                noDataIndication = { () => <NoDataIndication /> }
                filter={ filterFactory() }
                /*loading={ loading }  
                overlay={ overlayFactory({ spinner: <ClipLoader
                    color={'#a32273'}
                    loading={true}
                />}) }
                onTableChange={ handleTableChange}*/
                /*pagination={ 
                    paginationFactory({
                        //pageButtonRenderer
                        //hideSizePerPage: true
                    }) 
                }*/
                striped
                hover
                condensed
            />
            
        );
    }

}

ItemList.propTypes = {
    globalState: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    globalState: state.item
});
export default connect(mapStateToProps)(ItemList);

