module.exports = {
    ORDER_STATUS_BEING_AMENDED: 0,
    ORDER_STATUS_PENDING: 1,
    ORDER_STATUS_IN_PROGRESS: 2,
    ORDER_STATUS_REJECTED: 3,
    ORDER_STATUS_CANCELLED: 5,
    ORDER_STATUS_CANCELLED_AFTER_COLLECTION: 7,
    
    TASK_STATUS_ASSIGNED: 0,
    TASK_STATUS_STARTED: 1,
    TASK_STATUS_SUCCESSFUL: 2,
    TASK_STATUS_ARRIVED: 4,
    TASK_STATUS_ACCEPTED: 7,
    TASK_STATUS_DECLINED: 8,
    TASK_STATUS_CANCELLED: 9,
    TASK_STATUS_DELETED: 10,

    STATE_PENDING_UNREAD_RESTAURANT: 0,
    STATE_PENDING_HOPSCOTCH: 1,
    STATE_PENDING_READ_RESTAURANT: 2,
    STATE_PENDING_PROBLEM_RESTAURANT: 3,
    STATE_ITEM_ACCEPTED_TASK_UNASSIGNED: 4,
    STATE_TASK_ASSIGNED: 5,
    STATE_TASK_ACCEPTED: 6,
    STATE_TASK_DECLINED: 7,
    STATE_PICKUP_STARTED: 8,
    STATE_PICKUP_ARRIVED: 9,
    STATE_PICKUP_COMPLETE: 10,
    STATE_DELIVERY_STARTED: 11,
    STATE_DELIVERY_ARRIVED: 12,
    STATE_DELIVERY_COMPLETE: 13,
    STATE_ORDER_BEING_AMENDED: 14,
    STATE_ORDER_AMENDED_PENDING_HOPSCOTCH: 15,
    STATE_ORDER_AMENDED_PENDING_RESTAURANT: 16,
    STATE_ORDER_CANCELLED: 17,
    STATE_ORDER_CANCELLED_AFTER_COLLECTION: 18,
    STATE_ORDER_REJECTED: 19,
    STATE_FIRST_TIME_CUSTOMER_UNVERIFIED: 20,
    STATE_SWITCHED_RESTAURANT: 21,
    STATE_ITEM_ACCEPTED_ORDER_UNACCEPTED: 22,
    STATE_PICKUP_ORDER_COLLECTED: 23,

    CONDENSED_STATE_IN_PROGRESS: '0',
    CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION: '1',
    CONDENSED_STATE_NOT_IN_PROGRESS: '2'
}