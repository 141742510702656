const moment = require('moment'); 
const constants = require('../components/constants');

const STATE_PENDING_UNREAD_RESTAURANT = constants.STATE_PENDING_UNREAD_RESTAURANT;
const STATE_PENDING_HOPSCOTCH = constants.STATE_PENDING_HOPSCOTCH;
const STATE_PENDING_READ_RESTAURANT = constants.STATE_PENDING_READ_RESTAURANT;
const STATE_PENDING_PROBLEM_RESTAURANT = constants.STATE_PENDING_PROBLEM_RESTAURANT;
const STATE_ITEM_ACCEPTED_TASK_UNASSIGNED = constants.STATE_ITEM_ACCEPTED_TASK_UNASSIGNED;
const STATE_TASK_ASSIGNED = constants.STATE_TASK_ASSIGNED;
const STATE_TASK_ACCEPTED = constants.STATE_TASK_ACCEPTED;
const STATE_TASK_DECLINED = constants.STATE_TASK_DECLINED;
const STATE_PICKUP_STARTED = constants.STATE_PICKUP_STARTED;
const STATE_PICKUP_ARRIVED = constants.STATE_PICKUP_ARRIVED;
const STATE_PICKUP_COMPLETE = constants.STATE_PICKUP_COMPLETE;
const STATE_DELIVERY_STARTED = constants.STATE_DELIVERY_STARTED;
const STATE_DELIVERY_ARRIVED = constants.STATE_DELIVERY_ARRIVED;
const STATE_DELIVERY_COMPLETE = constants.STATE_DELIVERY_COMPLETE;
const STATE_ORDER_BEING_AMENDED = constants.STATE_ORDER_BEING_AMENDED;
const STATE_ORDER_AMENDED_PENDING_HOPSCOTCH = constants.STATE_ORDER_AMENDED_PENDING_HOPSCOTCH;
const STATE_ORDER_AMENDED_PENDING_RESTAURANT = constants.STATE_ORDER_AMENDED_PENDING_RESTAURANT;
const STATE_ORDER_CANCELLED = constants.STATE_ORDER_CANCELLED;
const STATE_ORDER_CANCELLED_AFTER_COLLECTION = constants.STATE_ORDER_CANCELLED_AFTER_COLLECTION;
const STATE_ORDER_REJECTED = constants.STATE_ORDER_REJECTED;
const STATE_FIRST_TIME_CUSTOMER_UNVERIFIED = constants.STATE_FIRST_TIME_CUSTOMER_UNVERIFIED;
const STATE_SWITCHED_RESTAURANT = constants.STATE_SWITCHED_RESTAURANT;
const STATE_ITEM_ACCEPTED_ORDER_UNACCEPTED = constants.STATE_ITEM_ACCEPTED_ORDER_UNACCEPTED;
const STATE_PICKUP_ORDER_COLLECTED = constants.STATE_PICKUP_ORDER_COLLECTED;

const CONDENSED_STATE_IN_PROGRESS = constants.CONDENSED_STATE_IN_PROGRESS;
const CONDENSED_STATE_NOT_IN_PROGRESS = constants.CONDENSED_STATE_NOT_IN_PROGRESS;
const CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION = constants.CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION;

const WARNING_WAIT_MINUTES = {};
WARNING_WAIT_MINUTES[STATE_PENDING_UNREAD_RESTAURANT] = 3;
WARNING_WAIT_MINUTES[STATE_PENDING_HOPSCOTCH] = 5;
WARNING_WAIT_MINUTES[STATE_PENDING_READ_RESTAURANT] = 3;
WARNING_WAIT_MINUTES[STATE_PENDING_PROBLEM_RESTAURANT] = 3;
WARNING_WAIT_MINUTES[STATE_ITEM_ACCEPTED_TASK_UNASSIGNED] = 3;
WARNING_WAIT_MINUTES[STATE_TASK_ASSIGNED] = 3;
WARNING_WAIT_MINUTES[STATE_TASK_ACCEPTED] = 3;
WARNING_WAIT_MINUTES[STATE_TASK_DECLINED] = 3;
WARNING_WAIT_MINUTES[STATE_PICKUP_ARRIVED] = 10;
WARNING_WAIT_MINUTES[STATE_PICKUP_COMPLETE] = 3;
WARNING_WAIT_MINUTES[STATE_DELIVERY_ARRIVED] = 7;
WARNING_WAIT_MINUTES[STATE_ORDER_BEING_AMENDED] = 5;
WARNING_WAIT_MINUTES[STATE_ORDER_AMENDED_PENDING_RESTAURANT] = 3;
WARNING_WAIT_MINUTES[STATE_ORDER_AMENDED_PENDING_HOPSCOTCH] = 3;
WARNING_WAIT_MINUTES[STATE_FIRST_TIME_CUSTOMER_UNVERIFIED] = 3;
WARNING_WAIT_MINUTES[STATE_ITEM_ACCEPTED_ORDER_UNACCEPTED] = 3;

const ITEM_STATUS_LABELS = {};
ITEM_STATUS_LABELS[STATE_PENDING_UNREAD_RESTAURANT] = { text: "Pending", color: "secondary" };
ITEM_STATUS_LABELS[STATE_PENDING_HOPSCOTCH] = { text: "Pending", color: "danger"  };
ITEM_STATUS_LABELS[STATE_PENDING_READ_RESTAURANT] = { text: "Seen", color: "secondary"  };
ITEM_STATUS_LABELS[STATE_PENDING_PROBLEM_RESTAURANT] = { text: "Problem", color: "danger"  };
ITEM_STATUS_LABELS[STATE_ITEM_ACCEPTED_TASK_UNASSIGNED] = { text: "Confirmed", color: "danger"  };
ITEM_STATUS_LABELS[STATE_TASK_ASSIGNED] = { text: "Assigned", color: "warning"  };
ITEM_STATUS_LABELS[STATE_TASK_ACCEPTED] = { text: "Accepted", color: "warning"  };
ITEM_STATUS_LABELS[STATE_TASK_DECLINED] = { text: "Declined", color: "danger"  };
ITEM_STATUS_LABELS[STATE_PICKUP_STARTED] = { text: "Started", color: "warning"  };
ITEM_STATUS_LABELS[STATE_PICKUP_ARRIVED] = { text: "Arrived", color: "warning"  };
ITEM_STATUS_LABELS[STATE_PICKUP_COMPLETE] = { text: "Collected", color: "warning"  };
ITEM_STATUS_LABELS[STATE_DELIVERY_STARTED] = { text: "Started", color: "primary"  };
ITEM_STATUS_LABELS[STATE_DELIVERY_ARRIVED] = { text: "Arrived", color: "primary"  };
ITEM_STATUS_LABELS[STATE_DELIVERY_COMPLETE] = { text: "Delivered", color: "success"  };
ITEM_STATUS_LABELS[STATE_ORDER_BEING_AMENDED] = { text: "Amending", color: "secondary"  };
ITEM_STATUS_LABELS[STATE_ORDER_AMENDED_PENDING_HOPSCOTCH] = { text: "Amended", color: "danger"  };
ITEM_STATUS_LABELS[STATE_ORDER_AMENDED_PENDING_RESTAURANT] = { text: "Amended", color: "secondary"  };
ITEM_STATUS_LABELS[STATE_ORDER_CANCELLED] = { text: "Cancelled", color: "secondary", title: "Cancelled before collection" };
ITEM_STATUS_LABELS[STATE_ORDER_CANCELLED_AFTER_COLLECTION] = { text: "Cancelled", color: "secondary",  title: "Cancelled after collection"};
ITEM_STATUS_LABELS[STATE_ORDER_REJECTED] = { text: "Rejected", color: "secondary"  };
ITEM_STATUS_LABELS[STATE_FIRST_TIME_CUSTOMER_UNVERIFIED] = { text: "Unverified", color: "danger"  };
ITEM_STATUS_LABELS[STATE_SWITCHED_RESTAURANT] = { text: "Switched", color: "secondary" };
ITEM_STATUS_LABELS[STATE_ITEM_ACCEPTED_ORDER_UNACCEPTED] = { text: "Confirmed", color: "secondary" };
ITEM_STATUS_LABELS[STATE_PICKUP_ORDER_COLLECTED] = { text: "Collected", color: "success"  };

module.exports = {
    getItemStateLabel: historyEvent => {
        const label = ITEM_STATUS_LABELS[historyEvent.status];
        if (historyEvent.status === STATE_PENDING_UNREAD_RESTAURANT || 
            historyEvent.status === STATE_ORDER_AMENDED_PENDING_RESTAURANT){
            label.title = `The restaurant will handle order confirmation.`;
        } else if (historyEvent.status === STATE_PENDING_HOPSCOTCH || 
            historyEvent.status === STATE_ORDER_AMENDED_PENDING_HOPSCOTCH){
            label.title = `Hopscotch to handle order confirmation.`;
        } else if (historyEvent.status === STATE_ITEM_ACCEPTED_TASK_UNASSIGNED 
            || historyEvent.status === STATE_ITEM_ACCEPTED_ORDER_UNACCEPTED){
            label.title = "";
            if(historyEvent.status === STATE_ITEM_ACCEPTED_TASK_UNASSIGNED){
                label.title = `Tasks to be assigned. `;
            } else {
                label.title = `Not all parts of this multi-restaurant order have been accepted. `;
            }
            
            const prep_time = historyEvent.extra.preparation_time;
            if(prep_time > 0 && historyEvent.extra.acceptance_time){
                label.title += `Preparation time: ${prep_time} mins. `;
                label.title += `Food to be ready for: ${
                    moment(new Date(historyEvent.extra.acceptance_time))
                    .add(prep_time, 'minutes').format("h:mma")}
                `;
            }
                
        } else if (historyEvent.status === STATE_PENDING_PROBLEM_RESTAURANT){
            label.title = `There's a problem with this order: ${historyEvent.extra.problem}`;
        } else if(historyEvent.extra){
            if(historyEvent.status === STATE_SWITCHED_RESTAURANT){
                label.title = `From: ${historyEvent.extra.from} To: ${historyEvent.extra.to}`;
            } else if (
                        historyEvent.status === STATE_TASK_ASSIGNED || 
                        historyEvent.status === STATE_TASK_ACCEPTED ||
                        historyEvent.status === STATE_TASK_DECLINED || 
                        historyEvent.status === STATE_PICKUP_STARTED || 
                        historyEvent.status === STATE_PICKUP_ARRIVED || 
                        historyEvent.status === STATE_PICKUP_COMPLETE || 
                        historyEvent.status === STATE_DELIVERY_STARTED || 
                        historyEvent.status === STATE_DELIVERY_ARRIVED || 
                        historyEvent.status === STATE_DELIVERY_COMPLETE  
                      ){
                switch(historyEvent.status){
                    case STATE_PICKUP_STARTED: 
                        label.title = `Pickup started. `;
                        break;
                    case STATE_PICKUP_ARRIVED: 
                        label.title = `Pickup arrived. `; 
                        break;
                    case STATE_DELIVERY_STARTED: 
                        label.title = `Delivery started. `; 
                        break;
                    case STATE_DELIVERY_ARRIVED: 
                        label.title = `Delivery arrived. `; 
                        break;
                    default:
                        label.title = "";
                }

                if(historyEvent.status === STATE_TASK_ASSIGNED && historyEvent.extra
                    && historyEvent.extra.driver){
                    label.title += `Driver: ${historyEvent.extra.driver}. `;   
                }

                const prep_time = historyEvent.extra.preparation_time;
                if( (historyEvent.status === STATE_TASK_ASSIGNED || 
                    historyEvent.status === STATE_TASK_ACCEPTED ||
                    historyEvent.status === STATE_TASK_DECLINED || 
                    historyEvent.status === STATE_PICKUP_STARTED || 
                    historyEvent.status === STATE_PICKUP_ARRIVED) &&
                    prep_time > 0 ){
                    label.title += `Food to be ready for: ${
                        moment(new Date(historyEvent.extra.acceptance_time))
                        .add(prep_time, 'minutes').format("h:mma")}
                    `;
                }
                
            }  
        }
        return label;
    }, 
    getCondensedItemState: item => {
        if(!item || !item.history || !item.history.length) return [];        
        const status = item.history[item.history.length-1].status;
        if(
            status === STATE_ORDER_CANCELLED || 
            status === STATE_ORDER_CANCELLED_AFTER_COLLECTION ||
            status === STATE_ORDER_REJECTED || 
            status === STATE_DELIVERY_COMPLETE ||
            status === STATE_PICKUP_ORDER_COLLECTED){
            return [CONDENSED_STATE_NOT_IN_PROGRESS];
        } else if(status === STATE_PENDING_HOPSCOTCH || 
                  status === STATE_PENDING_PROBLEM_RESTAURANT || 
                  status === STATE_ITEM_ACCEPTED_TASK_UNASSIGNED ||
                  status === STATE_TASK_DECLINED || 
                  status === STATE_ORDER_AMENDED_PENDING_HOPSCOTCH ||
                  status === STATE_FIRST_TIME_CUSTOMER_UNVERIFIED || 
                  (!!item && item.messages.length > 0 
                    && !!item.messages.find(m => m.sender !== "Hopscotch" && !m.readByHopscotch))
                ){
            return [CONDENSED_STATE_REQUIRES_HOPSCOTCH_ATTENTION, CONDENSED_STATE_IN_PROGRESS];
        } else {
            return [CONDENSED_STATE_IN_PROGRESS];
        }
    },
    getDeliveryEstimateMins:  item => {
        const dt = item.order.delivery_tasks[0];
         let lower, upper;

         try{
            lower = Math.round(
                (
                    (dt.estimated_collection_lower_percentile + 
                     dt.distance_from_restaurant_to_customer / 
                     dt.estimated_driving_speed_higher_percentile) * 60
                )/5)*5;
         } catch(err) {
             lower = null
         }
        
         try {
            upper = Math.round(
                (
                    (dt.estimated_collection_higher_percentile + 
                     dt.distance_from_restaurant_to_customer / 
                     dt.estimated_driving_speed_lower_percentile) * 60
                )/5)*5;
         } catch(err){
             upper = null;
         }    
        return [lower, upper];
    },
    isPending: status => {
            return status === STATE_PENDING_UNREAD_RESTAURANT ||
            status === STATE_PENDING_HOPSCOTCH ||
            status === STATE_ORDER_AMENDED_PENDING_HOPSCOTCH ||
            status === STATE_ORDER_AMENDED_PENDING_RESTAURANT ||
            status === STATE_FIRST_TIME_CUSTOMER_UNVERIFIED;
    },
    // Gets the last time that the item had the given status, or null, if it never had that status
    getStatusTime: (item, status) => {
        const event = item.history.slice().reverse().find(e => e.status === status);
        return (event ? new Date(event.at) : null);
    },
    // Get the time the order was placed. If the order was amended, this will be the latest amendment time
    getOrderDate: item => {
        const firstSwitchEvent = item.history.slice().find(
            evt => evt.status === STATE_SWITCHED_RESTAURANT); 
        const orderEventDate = item.history.slice().reverse().find(
            evt => (evt.status === STATE_ORDER_AMENDED_PENDING_HOPSCOTCH || 
                   evt.status === STATE_ORDER_AMENDED_PENDING_RESTAURANT) || 
                   ((evt.status === STATE_PENDING_UNREAD_RESTAURANT ||
                    evt.status === STATE_PENDING_HOPSCOTCH ||
                    evt.status === STATE_FIRST_TIME_CUSTOMER_UNVERIFIED)
                     && (!firstSwitchEvent || 
                    new Date(evt.at).getTime() < new Date(firstSwitchEvent.at).getTime())) 
                    ).at;
        return new Date(orderEventDate);
    }
}