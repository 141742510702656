import React, { Component } from 'react';
import Moment from 'react-moment';
import Button from 'reactstrap-button-loader';
import {
    Input,
    Container,
    Row,
    Col
} from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { sendMessage, readMessages } from '../actions/itemActions';

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            sendLoading: false,
            readLoading: false
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    isToday = someDate => {
        let today = new Date(); 
        return today.getFullYear() === someDate.getFullYear() 
        && today.getMonth() === someDate.getMonth()
        && today.getDate() === someDate.getDate();
    }

    callback = (action, success)  => {
        if(action === "send"){
            this.setState({ 
                sendLoading: false
            });
        } else {
            this.setState({ 
                readLoading: false
            });
        }
        
        if(!success){
            NotificationManager.error('There was an error sending or reading the message.');
        }
    }

    onSendClick = () => {
        const item = {
            id: this.props.item._id,
            message: {
                text: this.state.message,
                isProblem: false,
                sent: new Date(),
                sender: "Hopscotch",
                recipient: "Restaurant"
            }
        }
        this.props.sendMessage(item, this.callback);
        this.setState({ "message": "", sendLoading: true });
    }

    onReadClick = () => {
        const item = {
            id: this.props.item._id,
            readByHopscotch: new Date()
        };
        this.props.readMessages(item, this.callback);
        this.setState({ readLoading: true });
    }

    render(){
        const { item } = this.props;
        return (
            <Container style={{
                backgroundColor: '#D3D3D3',  
                paddingBottom: '1rem',
                marginBottom: '0.5rem', 
                borderRadius: '0.25em'}}>
                <Row><Col style={{fontWeight: 'bold'}}>MESSAGES</Col></Row>
                {item.messages.map(message => {
                    let isUnread = (message.sender !== "Hopscotch" && !message.readByHopscotch) || 
                    (message.sender === "Hopscotch" && !message.readByRestaurant);

                    return (
                    <Row key={item._id + message.sent + message.sender}>
                        <Col style={
                                { fontWeight: isUnread ? "bold" : "normal", 
                                  color: message.isProblem ? "red" : "initial"
                                }
                            }
                        >
                            ({this.isToday(new Date(message.sent)) ? 
                                <Moment format="h:mma" date={message.sent} local />:
                                <Moment format="MMM D, h:mma" date={message.sent} local />}) {
                                message.sender === "Hopscotch" ? 
                                "You wrote" : 
                                item.switched.length > 0 ?
                                `${message.sender} wrote` :
                                "Restaurant wrote" 
                            }: {message.text}
                        </Col>
                    </Row>       
                )})}
                <Input 
                    type="textarea" 
                    name="message" 
                    id="message" 
                    placeholder="Message the restaurant about this order."
                    style={{marginTop: '1rem', marginBottom: '1rem'}}
                    value={this.state.message}
                    onChange={this.onChange}
                />
                <Row style={{textAlign: 'center'}}>
                    <Col sm={{size: 4, offset: 2}}>
                        <Button 
                            loading={this.state.sendLoading} 
                            disabled={!this.state.message} 
                            className="btn-hopscotch" 
                            onClick={this.onSendClick}
                            style={{width: '100%'}}
                            >
                            {item.messages.find(m => m.sender !== "Hopscotch")?"Reply":"Send"}
                        </Button>
                    </Col>
                    <Col sm={{size: 4}}>
                        <Button 
                            loading={this.state.readLoading} 
                            disabled={!item.messages.find(m => m.sender !== "Hopscotch" && !m.readByHopscotch)} 
                            className="btn-hopscotch" 
                            onClick={this.onReadClick}
                            style={{width: '100%'}}
                            >
                                Read
                        </Button>   
                    </Col>
                </Row> 
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { sendMessage, readMessages })(Messages);