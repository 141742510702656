import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup
} from 'reactstrap';

import { connect } from 'react-redux';
import { verifyFirstTimeCustomer } from '../actions/itemActions';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
const constants = require('./constants');
const ORDER_STATUS_PENDING = constants.ORDER_STATUS_PENDING;

class VerifyFirstTimeCustomerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    callback = success  => {
        this.setState({ loading: false });
        this.toggle();
        if(success){
            NotificationManager.success('Customer successfully verified.');
        } else {
            NotificationManager.error('There was verifying the customer.');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        
        let verifyObj = {}; 
        verifyObj.item_id = this.props.item._id;
        verifyObj.first_time_customer_verified = new Date();
        this.props.verifyFirstTimeCustomer(verifyObj, this.callback);
        this.setState({ loading: true });
    }

    render(){
        const { item } = this.props;
        return (
            <>
                <Button className="btn-hopscotch"  
                        disabled={
                                    !item.order.first_time_customer || 
                                    !!item.first_time_customer_verified ||
                                    item.order.state !== ORDER_STATUS_PENDING
                                } 
                        style={{width: '100%'}}
                        onClick={this.toggle}>
                            Verify
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>First-time customer verification</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <span>This customer has never ordered with Hopscotch before. 
                                    <br/><br/>We can't proceed with their order until we confirm that we can reach 
                                    them, by phone call or WhatsApp, on one of the phone numbers they've provided:<br/><br/>
                                    <b>Phone number</b>: {parsePhoneNumberFromString(item.order.phone_number).formatInternational()}<br />
                                    <b>Alternative phone number</b>: 
                                    { item.order.phone_number_2 ?
                                    parsePhoneNumberFromString(item.order.phone_number).formatInternational() :
                                    ' None'}<br/><br/>
                                    
                                    Please click the button below once you've contacted the customer.
                                </span>
                                <Button 
                                    loading={this.state.loading} 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    disabled={
                                        !item.order.first_time_customer || 
                                        !!item.first_time_customer_verified ||
                                        item.order.state !== ORDER_STATUS_PENDING
                                    } 
                                    block
                                >
                                    Customer Verified
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalSettings: state.item
});
export default connect(mapStateToProps, { verifyFirstTimeCustomer })(VerifyFirstTimeCustomerModal);