import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';

import { connect } from 'react-redux';
import { completePickup } from '../actions/itemActions';

const ORDER_STATUS_IN_PROGRESS = require('./constants').ORDER_STATUS_IN_PROGRESS;
const ORDER_STATUS_PENDING = require('./constants').ORDER_STATUS_PENDING;

class CompletePickupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            final_order_total: ""
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            final_order_total: ""
        });
    }

    onChange = e => {
        if(!e.target.value || parseFloat(e.target.value) >= 0){
            this.setState({ [e.target.name]: e.target.value });
        } 
    }

    callback = success  => {
        this.setState({ loading: false });
        this.toggle();
        if(success){
            NotificationManager.success('Order fulfilled!');
        } else {
            NotificationManager.error('There was a problem updating the order.');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        
        let itemObj = {}; 
        itemObj.item_id = this.props.item._id;
        itemObj.pickup_completed = new Date();
        itemObj.final_order_total = this.state.final_order_total;

        this.props.completePickup(itemObj, this.callback);
        this.setState({ loading: true });
    }

    render(){
        const { item } = this.props;
        return (
            <>
                <Button 
                        className="btn-hopscotch" 
                        style={{ width: '100%'}} 
                        disabled={
                                    item.order.delivery_tasks[0].method !== "pickup" ||
                                    !!item.deleted || 
                                    !!item.cancelled || 
                                    !!item.cancelled_after_collection ||
                                    !!item.rejected ||
                                    !!item.pickup_completed ||
                                    (item.order.state === ORDER_STATUS_PENDING && !item.accepted) || 
                                    (item.order.state !== ORDER_STATUS_IN_PROGRESS && 
                                    item.order.state !== ORDER_STATUS_PENDING)
                                }
                        onClick={this.toggle}>
                            Fulfill
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Order Collected</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">    
                                        <InputGroupText>$</InputGroupText>   
                                    </InputGroupAddon>                    
                                    <Input 
                                        type="number" 
                                        name="final_order_total" 
                                        id="final_order_total" 
                                        placeholder="Please enter the receipt total"
                                        value={this.state.final_order_total}
                                        onChange={this.onChange}
                                        required
                                    />
                                </InputGroup>                               
                        
                                <Button 
                                    loading={this.state.loading} 
                                    disabled={
                                        !this.state.final_order_total ||
                                        item.order.delivery_tasks[0].method !== "pickup" ||
                                        !!item.deleted || 
                                        !!item.cancelled || 
                                        !!item.cancelled_after_collection ||
                                        !!item.rejected ||
                                        !!item.pickup_completed ||
                                        (item.order.state === ORDER_STATUS_PENDING && !item.accepted) ||
                                        (item.order.state !== ORDER_STATUS_IN_PROGRESS && 
                                        item.order.state !== ORDER_STATUS_PENDING)
                                    } 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    block
                                >
                                    Order Collected
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { completePickup })(CompletePickupModal);