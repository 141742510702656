import React, { Component } from 'react';
import {
    Tooltip,
    Badge
} from 'reactstrap';

import { connect } from 'react-redux';
const utils = require('../utils/ItemUtils');
const moment = require('moment'); 

class StatusBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
        }
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
          }); 
    }

    render(){
        const { item } = this.props;
        const lastEvent = item.history.length > 0 ? item.history[item.history.length-1] : null;
        const { extra } = lastEvent;
        const label = utils.getItemStateLabel(lastEvent);
        const showTimeUntilFoodReady = !!extra && !!extra.acceptance_time && !!extra.preparation_time 
                                        && extra.preparation_time > 0;
        const showTimeUntilDriverArrives = !!extra && 
                                        (!!extra.pickup_start_time || !!extra.delivery_start_time);
         
        let readyMoment, arrivalMoment;
        
        if(showTimeUntilFoodReady){
            readyMoment = moment(new Date(extra.acceptance_time))
                        .add(extra.preparation_time, 'minutes');
                        
        }

        if(showTimeUntilDriverArrives){
            let ref_time = extra.pickup_start_time || extra.delivery_start_time;
            arrivalMoment = moment(new Date(ref_time)).add(lastEvent.warning_wait, 'minutes');
        }
        
        // Always show exact number of minutes. By default, moment will convert the display value to hours
        moment.relativeTimeThreshold("m", 1000000);

        return (
            <>
                <Badge id={"ID"+item._id + "state"} color={label.color} pill>{label.text}
                </Badge>{
                    label.title ?
                    <Tooltip 
                        placement="right" 
                        target={"ID"+item._id + "state"} 
                        toggle={this.toggle} 
                        isOpen={this.state.tooltipOpen}
                    >
                        {label.title} 
                        {
                            showTimeUntilFoodReady && showTimeUntilDriverArrives ? 
                            ` (${readyMoment.fromNow()}). Driver to arrive at: ${arrivalMoment.format("h:mma")} 
                              (${arrivalMoment.fromNow()}).`
                            :
                            showTimeUntilFoodReady ? 
                            ` (${readyMoment.fromNow()}).`
                            : 
                            showTimeUntilDriverArrives ? 
                            ` Driver to arrive at: ${arrivalMoment.format("h:mma")} 
                              (${arrivalMoment.fromNow()}).`
                            : 
                            "" 
                        }
                        
                    </Tooltip> 
                    :
                    ""
                }
                
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, {  })(StatusBadge);