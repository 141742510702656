import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    CustomInput
} from 'reactstrap';

import { connect } from 'react-redux';
import { cancelOrder, cancelOrderAfterCollection, cancelTasks } from '../actions/itemActions';
const constants = require('./constants');
const utils = require('../utils/ItemUtils');

const ORDER_STATUS_CANCELLED = constants.ORDER_STATUS_CANCELLED;
const ORDER_STATUS_CANCELLED_AFTER_COLLECTION = constants.ORDER_STATUS_CANCELLED_AFTER_COLLECTION;
const ORDER_STATUS_REJECTED = constants.ORDER_STATUS_REJECTED;
const ORDER_STATUS_PENDING = constants.ORDER_STATUS_PENDING;
const ORDER_STATUS_IN_PROGRESS = constants.ORDER_STATUS_IN_PROGRESS;
const STATE_PICKUP_COMPLETE = constants.STATE_PICKUP_COMPLETE;
const ORDER_STATUS_BEING_AMENDED = constants.ORDER_STATUS_BEING_AMENDED;


class CancellationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            cancelOrder: true,
            reimburseRestaurant: !!props.item.pickup_task && !!utils.getStatusTime(props.item, STATE_PICKUP_COMPLETE)
            
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    onChangeCheckbox = e => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    onChangeRadio = e => {
        this.setState({ 
            [e.target.name]: e.target.value === "cancelOrder"
        });
    }

    callback = success  => {
        this.setState({ loading: false });
        this.toggle();
        if(success){
            NotificationManager.success('You\'ve cancelled the order. ');
        } else {
            NotificationManager.error('There was a problem cancelling the order.');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        let cancelObj = {}; 
        cancelObj.item_id = this.props.item._id;
        cancelObj.cancelled = new Date();
        
        if(!this.state.cancelOrder){
            this.props.cancelTasks(cancelObj, this.callback);
        } else {
            if(!this.state.reimburseRestaurant){
                this.props.cancelOrder(cancelObj, this.callback);
            } else {
                this.props.cancelOrderAfterCollection(cancelObj, this.callback);
            }
        }
        
        this.setState({ loading: true });
    }

    render(){
        const { item } = this.props;
        const isPickup = (item.order.delivery_tasks[0] && item.order.delivery_tasks[0].method === "pickup");
        return (
            <>
                <Button color="danger"  style={{width: '100%'}}
                        disabled={
                                    !!item.deleted || 
                                    !!item.cancelled || 
                                    !!item.cancelled_after_collection || 
                                    !!item.rejected || 
                                    item.order.state === ORDER_STATUS_CANCELLED ||
                                    item.order.state === ORDER_STATUS_CANCELLED_AFTER_COLLECTION || 
                                    item.order.state === ORDER_STATUS_REJECTED ||
                                    item.order.state === ORDER_STATUS_BEING_AMENDED
                                }
                        onClick={this.toggle}>
                            Cancel
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Confirm Cancellation</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <span>Please cancel the order if the customer no longer wants to proceed with it.<br/><br/></span>
                                {item.is_multi_restaurant_order ? 
                                    item.order.state === ORDER_STATUS_PENDING ?
                                        <p>
                                            <span style={{color: "red"}}>NOTE: This is part of a multi-restaurant order. 
                                                Cancelling it {!isPickup ? "at this point ":""}will also cancel the other parts of the order. 
                                                If that isn't your intention, please amend the order instead.
                                            </span>
                                        </p>
                                        : item.order.state === ORDER_STATUS_IN_PROGRESS ?
                                           isPickup ?
                                            <p>
                                                <span style={{color: "red"}}>NOTE: This is part of a multi-restaurant order. 
                                                    Cancelling it will also cancel the other parts of the order. 
                                                </span>
                                            </p>
                                            :
                                            <p>
                                                <span style={{color: "red"}}>NOTE: This is part of a multi-restaurant order. 
                                            You can cancel the entire order (along with all delivery
                                            tasks for all restaurants in the order), or only cancel the 
                                            delivery tasks associated with {item.order.restaurants[0].name}. 
                                            </span> 
                                            </p>
                                        : "" 
                                    : ""
                                }
                                
                                <div style={item.order.state !== ORDER_STATUS_IN_PROGRESS || 
                                !item.is_multi_restaurant_order || isPickup ? 
                                    {display: 'none'} :
                                    {}
                                }>
                                    <CustomInput 
                                        type="radio" 
                                        id="cancelOrder"
                                        name="cancelOrder" 
                                        label="Cancel the order" 
                                        onChange={this.onChangeRadio}
                                        checked={this.state.cancelOrder}
                                        value="cancelOrder"
                                        inline />
                                    <CustomInput 
                                        type="radio" 
                                        id="cancelTasks"
                                        name="cancelOrder" 
                                        label={`Only cancel the tasks for ${item.order.restaurants[0].name}`} 
                                        onChange={this.onChangeRadio}
                                        checked={!this.state.cancelOrder}
                                        value="cancelTasks"
                                        inline />
                                </div>

                                <div style={item.order.state !== ORDER_STATUS_IN_PROGRESS || 
                                    !this.state.cancelOrder || isPickup ? 
                                    {display: 'none'} :
                                    {}
                                }><br />
                                    <CustomInput 
                                        type="checkbox" 
                                        id="reimburseRestaurant"
                                        name="reimburseRestaurant" 
                                        label="Reimburse restaurant for the food cost?" 
                                        onChange={this.onChangeCheckbox}
                                        checked={this.state.reimburseRestaurant}
                                        inline />
                                </div> 
                                
                                
                                
                            </FormGroup>
                            <FormGroup>
                                <Button 
                                    loading={this.state.loading} 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    disabled={
                                        !!item.deleted || 
                                        !!item.cancelled || 
                                        !!item.cancelled_after_collection || 
                                        !!item.rejected || 
                                        item.order.state === ORDER_STATUS_CANCELLED ||
                                        item.order.state === ORDER_STATUS_CANCELLED_AFTER_COLLECTION || 
                                        item.order.state === ORDER_STATUS_REJECTED ||
                                        item.order.state === ORDER_STATUS_BEING_AMENDED
                                    }
                                    block
                                >
                                    Cancel Order
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalSettings: state.item
});
export default connect(mapStateToProps, { 
        cancelOrder, 
        cancelOrderAfterCollection,
        cancelTasks
     })(CancellationModal);