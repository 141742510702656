import React, { Component } from 'react';
import { connect } from 'react-redux';
import Timer from 'react-compound-timer';
import OrderHistoryPopover from './OrderHistoryPopover';
const constants = require('./constants');
const utils = require('../utils/ItemUtils');

const STATE_ORDER_CANCELLED = constants.STATE_ORDER_CANCELLED;
const STATE_ORDER_CANCELLED_AFTER_COLLECTION = constants.STATE_ORDER_CANCELLED_AFTER_COLLECTION;
const STATE_ORDER_REJECTED = constants.STATE_ORDER_REJECTED;
const STATE_DELIVERY_ARRIVED = constants.STATE_DELIVERY_ARRIVED;
const STATE_DELIVERY_COMPLETE = constants.STATE_DELIVERY_COMPLETE;

const STATE_ORDER_AMENDED_PENDING_HOPSCOTCH = constants.STATE_ORDER_AMENDED_PENDING_HOPSCOTCH;
const STATE_ORDER_AMENDED_PENDING_RESTAURANT = constants.STATE_ORDER_AMENDED_PENDING_RESTAURANT;

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
    <Timer {...timerProps}>
      {timerRenderProps =>
        <WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
    </Timer>
  );
  
class DeliveryTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warn: false,
            show: true
        }
    }

    componentDidMount() {
        const { setTime, stop, start } = this.props.timer;
        const { item } = this.props;
        const itemState = item.history[item.history.length-1];
        const upperDeliveryEstimateMins = utils.getDeliveryEstimateMins(item)[1];
        
        //const orderEventTime = utils.getOrderDate(item);
        const orderEventTime = new Date(item.order.order_date);
        const arrivalTime = utils.getStatusTime(item, STATE_DELIVERY_ARRIVED);
        const rejectionTime = utils.getStatusTime(item, STATE_ORDER_REJECTED);
        const cancellationTime = utils.getStatusTime(item, STATE_ORDER_CANCELLED) || 
                                 utils.getStatusTime(item, STATE_ORDER_CANCELLED_AFTER_COLLECTION);

        if(!itemState){
            this.setState({show: false});
        } else if(itemState.status === STATE_DELIVERY_COMPLETE
            || itemState.status === STATE_ORDER_CANCELLED
            || itemState.status === STATE_ORDER_CANCELLED_AFTER_COLLECTION 
            || itemState.status === STATE_ORDER_REJECTED
            || (!this.props.inPopover && itemState.status === STATE_DELIVERY_ARRIVED)){
            let referenceTime = (!this.props.inPopover ? (rejectionTime || cancellationTime || arrivalTime) : itemState.at);
            if(!referenceTime){
                this.setState({show: false});
            } else {
                let totalTimeMillis = Math.max(0, new Date(referenceTime).getTime() - orderEventTime.getTime());
                setTime(totalTimeMillis);
                start();
                stop();            
                if((itemState.status === STATE_DELIVERY_COMPLETE ||
                    (!this.props.inPopover && itemState.status === STATE_DELIVERY_ARRIVED)) &&
                    upperDeliveryEstimateMins && 
                    arrivalTime && arrivalTime.getTime() - orderEventTime.getTime() 
                        > upperDeliveryEstimateMins * 60 * 1000){
                    this.setState({warn: true});
                }

                if(this.props.inPopover){
                    this.setState({show: false});
                }
            }
            
        } else {
            this.startTimer();
        }
    }

    componentDidUpdate(prevProps){
        const { stop, setTime, setCheckpoints } = this.props.timer;
        const { item } = this.props;
        const prevItem = prevProps.item;

        const itemState = item.history[item.history.length-1]; 
        let prevItemState; 
        if(prevItem && prevItem.history.length > 0){
            prevItemState = prevItem.history[prevItem.history.length-1]; 
        }
        
        if(prevItemState.status !== itemState.status){
            if(!itemState 
                || itemState.status === STATE_ORDER_CANCELLED
                || itemState.status === STATE_ORDER_CANCELLED_AFTER_COLLECTION
                || itemState.status === STATE_ORDER_REJECTED
                || itemState.status === STATE_DELIVERY_COMPLETE
                || (!this.props.inPopover && itemState.status === STATE_DELIVERY_ARRIVED)){
                stop();
                if(this.props.inPopover){
                    this.setState({show: false});
                }
                /*if(!itemState || itemState.status !== STATE_DELIVERY_ARRIVED){
                    this.setState({show: false});
                }  */
            } else if(
                    // Amended orders
                    itemState.status === STATE_ORDER_AMENDED_PENDING_HOPSCOTCH ||
                    itemState.status === STATE_ORDER_AMENDED_PENDING_RESTAURANT 
                ) {
                
                const now = new Date();
                //const orderEventDate = utils.getOrderDate(item);
                const orderEventDate = new Date(item.order.order_date);
                setTime(Math.max(0,now.getTime() - orderEventDate.getTime()));
                const upperDeliveryEstimateMins = utils.getDeliveryEstimateMins(item)[1];
                if(upperDeliveryEstimateMins){
                    setCheckpoints([
                        {
                            time: upperDeliveryEstimateMins * 60 * 1000,
                            callback: () => this.setState({warn: true})
                        }
                    ]); 
                }
                // this.startTimer();
                if(this.state.warn) this.setState({warn: false});
            } else if (
                        prevItemState.status === STATE_ORDER_CANCELLED || 
                        prevItemState.status === STATE_ORDER_CANCELLED_AFTER_COLLECTION || 
                        prevItemState.status === STATE_ORDER_REJECTED
                ){
                    // This can happen if part of a multi-restaurant order is, eg, cancelled, but then 
                    // another part is accepted. This would lead the order to be accepted in Django.
                    this.startTimer();
            }
        } 
    }

    startTimer = () => {
        const { item, timer } = this.props;
        const orderEventTime = new Date(item.order.order_date);
        const { setCheckpoints, setTime, start } = timer;
        
        const upperDeliveryEstimateMins = utils.getDeliveryEstimateMins(item)[1];
        const now = new Date();
        
        setTime(Math.max(0, now.getTime() - orderEventTime.getTime()));
        start();

        if(upperDeliveryEstimateMins){
            const timeToWarn = Math.max(0, 
                orderEventTime.getTime() + 
                upperDeliveryEstimateMins * 60 * 1000 - now.getTime());
            
            if(timeToWarn === 0){
                this.setState({warn: true});
            } else {
                setCheckpoints([
                    {
                        time: upperDeliveryEstimateMins * 60 * 1000,
                        callback: () => this.setState({warn: true})
                    }
                ]);
            }
        }
    }

    render() { 
        const { item } = this.props;
        return (
        <>
            <span 
                id={`deliveryToggler${item._id}`}
                style={
                        !this.state.show ? 
                            {display: 'none'} : 
                            this.state.warn ? 
                                {color: 'red'} : 
                                {}}
            >
                <Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)}`}/>
                :<Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`}/>
            </span>
            {
                !this.props.inPopover ?
                <OrderHistoryPopover targetElement={`deliveryToggler${item._id}`} item={item} /> :
                ""
            }
        </>
        );
    }
}

const TimerHOC = withTimer({
    lastUnit:"m",
    startImmediately: false
})(DeliveryTimer);

const mapStateToProps = state => ({
    globalState: state.item
});

export default connect(mapStateToProps, {  })(TimerHOC);