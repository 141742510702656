import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import EmailModal from './EmailModal';
import { connect } from 'react-redux';

class Emails extends Component {
    render(){
        const { item } = this.props;
        return (
            <Container style={{
                backgroundColor: '#D3D3D3', 
                borderRadius: '0.25em'}}>
                <Row><Col style={{fontWeight: 'bold'}}>EMAILS</Col></Row>
                {item.emails.length > 0 ? 
                    item.emails.map(email => (
                        <Row key={item._id + email.sent + email.subject}>
                            <Col>
                                <EmailModal email={email} item={item} />
                            </Col>
                        </Row>       
                    ))
                    :
                    <span>You've sent no emails.</span>
                }
                 <Row style={{textAlign: 'center', marginTop: '0.5rem'}}>
                     <Col sm={{offset: 4, size: 4}}>
                        <EmailModal to={item.order.email} item={item} />
                    </Col>
                </Row> 
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, {  })(Emails);