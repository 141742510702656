import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import EmailModal from './EmailModal';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Moment from 'react-moment';

class OrderDetails extends Component {
    render(){
        const { item } = this.props;
        const order = item.order;
        const delivery_task = order.delivery_tasks[0] || {};
        const extra_charges = order.order_extra_charges;
        const menu_items = order.order_menu_items;
        const address = !order.address_line_1 ? null : [
                            order.address_line_1, order.address_line_2, order.parish
                        ].join(", ").replace(", , ", ", ");
        return (
            <Row>
                <Container style={{paddingBottom: '1rem'}}>     
                    <Container style={{paddingBottom: '1rem', paddingLeft: '0'}}>
                        <Row style={{fontWeight: 'bold'}}>
                            <Col sm="12">Customer Details</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Name:</Col>
                            <Col>{[order.first_name, order.last_name].join(" ")}</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Phone:</Col>
                            <Col>{order.phone_number ? 
                                parsePhoneNumberFromString(order.phone_number)
                                .formatInternational() : "None"}</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Alternate Phone:</Col>
                            <Col>{order.phone_number_2 ? 
                                parsePhoneNumberFromString(order.phone_number_2)
                                .formatInternational() : "None"}</Col>
                        </Row>
                        <Row>
                            <Col sm="3">Email:</Col>
                            <Col><EmailModal link={order.email} item={item} /></Col>
                        </Row>
                        <Row>
                            <Col sm="3">Address:</Col>
                            <Col>
                                {
                                    address ?
                                    <a 
                                    target="_blank" rel="noopener noreferrer"
                                    href={`http://www.google.com/maps/place/${order.location.coordinates[1]},${order.location.coordinates[0]}`}>
                                    {address}
                                    </a> :
                                    "None"
                                }
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3">Delivery Instructions:</Col>
                            <Col>{order.delivery_instructions || "None"}</Col>
                        </Row>
                        {delivery_task.delivery_time_to ? 
                        <Row>
                            <Col sm="3" style={{ color: "red"}}>Pre-order for:</Col>
                            <Col style={{ color: "red"}}>
                                <Moment 
                                    format={delivery_task.pre_order_time==='range' ? "dddd, MMMM Do" : "dddd, MMMM Do, h:mma"}
                                    date={new Date(delivery_task.delivery_time_to)} local 
                                />
                            </Col>
                        </Row>
                        :
                        ''
                        }
                        <Row>
                            <Col sm="3" style={{ color: "red"}}>Pickup or Delivery?</Col>
                            <Col style={{ color: "red"}}>
                                {delivery_task.method === "pickup" ? "Pickup" : "Delivery"}
                            </Col>
                        </Row>
                    </Container>
                    <Row style={{fontWeight: 'bold'}}>
                            <Col sm="12">Order Details (ID: {order.id})</Col>
                    </Row>   
                    {menu_items.map((menu_item, index) => (
                    <Container style={{paddingBottom: '1rem', paddingLeft: '0'}} key={index}>
                        <Row style={{fontWeight: 'bold'}}>
                            <Col sm="10">
                                {menu_item.menu_item ? <span>{menu_item.quantity} x </span> : ""}
                                {
                                    menu_item.menu_item ?  
                                    <span style={{textDecoration: 'underline'}}>{menu_item.name}</span> :
                                    <div>
                                        {menu_item.name.split("\n").map((i,key) => {
                                            return <div key={key}>{i}</div>;
                                        })}
                                    </div>    
                                }
                            </Col>

                            <Col>{menu_item.menu_item ? `$${menu_item.total}` : ""}</Col>
                        </Row>
                        {menu_item.options_descriptions.map((option, index) => (
                        <Row key={index}>
                            <Col>{option.key}: {option.value}</Col>
                        </Row>
                        ))}
                        {menu_item.special_instructions && menu_item.menu_item ? 
                        <>
                            <Row style={{paddingTop: '1rem', fontWeight: 'bold'}}>
                                <Col>SPECIAL REQUESTS</Col>
                            </Row>
                            <Row> 
                                <Col>{menu_item.special_instructions}</Col>
                            </Row>
                        </>
                        :
                        menu_item.substitutes ? 
                        <>
                            <Row style={{paddingTop: '1rem', fontWeight: 'bold'}}>
                                <Col>Substitutes</Col>
                            </Row>
                            <Row> 
                                <Col>
                                    <div>
                                            {menu_item.substitutes.split("\n").map((i,key) => {
                                                return <div key={key}>{i}</div>;
                                            })}
                                    </div>
                                </Col>
                            </Row>
                        </>
                        :
                        ''
                        }
                    </Container> 
                    ))}
                    <Container style={{paddingBottom: '1rem', paddingLeft: '0'}}> 
                        <Row>
                            <Col sm="10">{extra_charges.length > 0 ? 
                                "Food subtotal" : "Food total"}</Col>
                            <Col>{delivery_task.original_food_cost ? `$${delivery_task.original_food_cost}` : "TBD"}</Col>
                        </Row>
                        {extra_charges.map((extra_charge, index) => (
                            <Row key={index}>
                                <Col sm="10">{extra_charge.name}</Col>
                                <Col>${Number(extra_charge.fee).toFixed(2)}</Col>
                            </Row>
                        ))}
                        {extra_charges.length > 0 ? (
                            <Row>
                                <Col sm="10">Food total</Col>
                                <Col>$
                                    {Object
                                        .entries(delivery_task)
                                        .reduce((previousValue, entry) => 
                                            previousValue + 
                                            (
                                                entry[0] === "original_food_cost" || 
                                                entry[0] === "extra_charge_amount" ? 
                                                    Number(entry[1]) : 0 ), 0
                                        ).toFixed(2)  
                                    }
                                </Col>
                            </Row>
                        ) : ''}
                        <Row>
                            <Col sm="10">Delivery fee</Col>
                            <Col>${delivery_task.delivery_cost}</Col>
                        </Row>
                        {delivery_task.service_fee ?
                            <Row>
                                <Col sm="10">Service fee</Col>
                                <Col>{delivery_task.service_fee ? 
                                    `$${Number(delivery_task.service_fee).toFixed(2)}` : 
                                    "To be determined"
                                }</Col>
                            </Row> : ""
                        }
                        <Row>
                            <Col sm="10">Total</Col>
                            <Col>{delivery_task.total ? `$${Number(delivery_task.total).toFixed(2)}` : "TBD"}</Col>
                        </Row>
                        <Row>
                            <Col sm="10">Payment {delivery_task.method === "pickup"? "" : "by"}</Col>
                            <Col>{delivery_task.method === "pickup" ? 
                            (order.payment_type===1 ? "At the restaurant" : "Card, prepaid online") : 
                            (order.payment_type===1 ? "Cash" : "Card")
                            }</Col>
                        </Row>
                    </Container>
                </Container>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    globalState: state.item
});
export default connect(mapStateToProps, { })(OrderDetails);