import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody, Table, Badge, UncontrolledTooltip } from 'reactstrap';
import StateTimer from './StateTimer';
import DeliveryTimer from './DeliveryTimer';
import Moment from 'react-moment';
import { connect } from 'react-redux';

const moment = require("moment-timezone");
const momentDurationFormatSetup = require("moment-duration-format");
const utils = require('../utils/ItemUtils');
const constants = require('./constants');
const STATE_SWITCHED_RESTAURANT = constants.STATE_SWITCHED_RESTAURANT;

momentDurationFormatSetup(moment);

class OrderHistoryPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    
    toggle = () => {
        this.setState({
            open: !this.state.open,
        });
    }

    render(){
        const { item } = this.props;
        //const orderEventDate = utils.getOrderDate(item);     
        const orderEventDate = new Date(item.order.order_date);
        const upperDeliveryEstimateMins = utils.getDeliveryEstimateMins(item)[1];

        return (
                <Popover 
                    placement="auto" 
                    trigger="hover" 
                    isOpen={this.state.open} 
                    target={this.props.targetElement} 
                    toggle={this.toggle}
                    className="popover-hopscotch"
                    delay={{hide: 500}}
                    >
                    <PopoverHeader style={{textAlign: 'center'}}>Order history</PopoverHeader>
                    <PopoverBody>
                        <Table className="historyTable" size="sm">
                            <thead>
                            <tr>
                                <th>Status</th>
                                <th>Reached At</th>
                                <th>Time in Status</th>
                                <th>Total Time*</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    item.history.length ? item.history.map((evt, index, hist) => { 
                                        let label = utils.getItemStateLabel(evt);
                                        return (
                                            <tr key={item._id+index}>
                                                <td>
                                                    <Badge id={"ID"+item._id + "history"+index} color={label.color} pill>{label.text}
                                                    </Badge>
                                                    {
                                                        label.title ?
                                                        <UncontrolledTooltip placement="right" target={"ID"+item._id + "history"+index}>
                                                            {label.title}
                                                        </UncontrolledTooltip> :
                                                        ""
                                                    }
                                                    
                                                </td>
                                                <td style={{textAlign: 'center'}}><Moment format="h:mma" date={evt.at} local /></td>
                                                <td style={{textAlign: 'center'}}>{
                                                    evt.status !== STATE_SWITCHED_RESTAURANT ?
                                                        index < hist.length - 1 ?
                                                        <span style={
                                                            evt.warning_wait && (new Date(hist[index+1].at).getTime() - 
                                                            new Date(evt.at).getTime()) 
                                                            > evt.warning_wait * 60 * 1000 ? 
                                                                {color: 'red'} : {}}>
                                                            {moment.duration(
                                                                Math.max(0, new Date(hist[index+1].at).getTime() -
                                                                new Date(evt.at).getTime()),
                                                                "milliseconds"
                                                            ).format("mm:ss", {trim: false})}
                                                        </span>
                                                            :
                                                        <StateTimer inPopover={true} item={item} />
                                                    : ""
                                                }</td>
                                                <td style={{textAlign: 'center'}}>{
                                                        evt.status !== STATE_SWITCHED_RESTAURANT ?
                                                            index < hist.length - 1 ?
                                                            <span style={
                                                                upperDeliveryEstimateMins && 
                                                                (new Date(hist[index+1].at).getTime() - 
                                                                orderEventDate.getTime()) 
                                                                > upperDeliveryEstimateMins * 60 * 1000 ? 
                                                                    {color: 'red'} : {}}>
                                                                {moment.duration(
                                                                Math.max(0, new Date(hist[index+1].at).getTime() -
                                                                orderEventDate.getTime()),
                                                                "milliseconds"
                                                                ).format("mm:ss", {trim: false})}
                                                            </span>
                                                            :
                                                            <DeliveryTimer inPopover={true} item={item}/>
                                                        : ""
                                                    }
                                                    </td>
                                            </tr>
                                        )
                                    })

                                    :
                                    <tr><td colspan="4" align="center">No history yet.</td></tr>
                                }
                                
                            </tbody>
                        </Table>
                        <div><span style={{fontStyle: 'italic'}}>*From latest amendment time.</span></div>
                    </PopoverBody>
                </Popover>
        );
    }
}

const mapStateToProps = state => ({
    globalSettings: state.item
});
export default connect(mapStateToProps, {  })(OrderHistoryPopover);