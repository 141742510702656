import React, { Component } from 'react';
import Button from 'reactstrap-button-loader';
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup
} from 'reactstrap';

import { connect } from 'react-redux';
import { rejectOrder } from '../actions/itemActions';
const constants = require('./constants');

const ORDER_STATUS_PENDING = constants.ORDER_STATUS_PENDING;

class RejectionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    callback = success  => {
        this.setState({ loading: false });
        this.toggle();
        if(success){
            NotificationManager.success('You\'ve rejected the order. ');
        } else {
            NotificationManager.error('There was a problem rejecting the order.');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        
        let rejectObj = {}; 
        rejectObj.item_id = this.props.item._id;
        rejectObj.rejected = new Date();
        this.props.rejectOrder(rejectObj, this.callback);
        this.setState({ loading: true });
    }

    render(){
        const { item } = this.props;
        return (
            <>
                <Button color="danger"  style={{width: '100%'}}
                        disabled={
                                    !!item.deleted || 
                                    !!item.accepted ||
                                    !!item.cancelled || 
                                    !!item.cancelled_after_collection ||
                                    !!item.rejected || 
                                    item.order.state !== ORDER_STATUS_PENDING 
                                }
                        onClick={this.toggle}>
                            Reject
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Confirm Rejection</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>    
                            <FormGroup>
                                <span>Please reject the order if Hopscotch (or the restaurant) is unable to fulfill it. </span>
                                {item.is_multi_restaurant_order ? 
                                    <p><br />
                                        <span style={{color: 'red'}}>NOTE: This is part of a multi-restaurant order. 
                                        Rejecting it will also reject the other parts of the order. If that isn't your intention, 
                                        please amend the order instead.</span>
                                    </p> :
                                    ""
                                }
                                <Button 
                                    loading={this.state.loading} 
                                    className="btn-hopscotch" 
                                    style={{ marginTop: '2rem'}} 
                                    disabled={
                                        !!item.deleted || 
                                        !!item.accepted ||
                                        !!item.cancelled || 
                                        !!item.cancelled_after_collection ||
                                        !!item.rejected || 
                                        item.order.state !== ORDER_STATUS_PENDING 
                                    }
                                    block
                                >
                                    Reject Order
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    globalSettings: state.item
});
export default connect(mapStateToProps, { rejectOrder })(RejectionModal);