import React, { Component } from 'react';
import SettingsModal from './SettingsModal';
import { connect } from 'react-redux';
import logo from '../static/hopscotch_logo2.png';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Container
} from 'reactstrap';
import DatePicker from "react-datepicker";

import { 
    getItems, 
    setDate,
    addItems,
    updateItems,
    setItemsLoading
} from '../actions/itemActions';
import PubNubReact from 'pubnub-react';
import audio from '../static/hopscotch_audio.mp3';
 
import "react-datepicker/dist/react-datepicker.css";
const constants = require('../components/constants');
const STATE_ORDER_CANCELLED = constants.STATE_ORDER_CANCELLED;
const STATE_ORDER_CANCELLED_AFTER_COLLECTION = constants.STATE_ORDER_CANCELLED_AFTER_COLLECTION;
const STATE_ORDER_REJECTED = constants.STATE_ORDER_REJECTED;

let jingle = new Audio(audio);

class AppNavbar extends Component {
    constructor(props) {
        super(props);
        this.initializePubNub();
        this.props.setItemsLoading();
        this.props.setDate(new Date());
        this.props.getItems();
    }
    pubnubListener = { 
        message: payload => {  
            if (payload.message.refresh /*&& payload.message.refreshHopscotchType !== 'task'*/){
                //this.props.setItemsLoading();
                //console.log(`Message is ${JSON.stringify(payload.message)}`);
                if(payload.message.new_items || payload.message.updated_items){
                    if(payload.message.new_items){
                        this.props.addItems(payload.message.new_items);
                    } 
                    
                    if (payload.message.updated_items){
                        // If a restaurant sends a message on an item that isn't showing on MC (eg, it's from today, complete 
                        // and MC has "Don't show complete items" enabled, or it's from yesterday and complete), 
                        // then add it 
                        if(payload.message.updated_items.length===1 && 
                            !this.props.globalState.items.find(item => item._id===payload.message.updated_items[0]._id) && 
                            payload.message.updated_items[0].messages.some(m => m.sender !== "Hopscotch" && !m.readByHopscotch)
                            ){
                            this.props.addItems(payload.message.updated_items);
                        } else {
                            this.props.updateItems(payload.message.updated_items);
                        }
                    }
                } else {
                    this.props.setItemsLoading();
                    this.props.getItems();
                }
                
            }

            if (payload.message.playJingle){
                this.playJingle();
            }
        } 
    }

    componentDidUpdate(prevProps){
        const settings = this.props.globalState.settings;
        let getItems = false;
        if(new Date(prevProps.globalState.settings.date).toDateString() !== 
           new Date(settings.date).toDateString()){
            //this.props.setItemsLoading();
           // this.props.setDate(new Date(settings.date));
            //this.props.getItems(this.callback);
            getItems = true;
        }
        if(prevProps.globalState.settings.hopscotch_id !== settings.hopscotch_id){
            this.initializePubNub();
            //this.props.setItemsLoading();
            //this.props.getItems(this.callback);    
            getItems = true; 
        } 

        if(prevProps.globalState.settings.showAllOrders !== settings.showAllOrders){
            getItems= true;
        }

        if(getItems){
            this.props.setItemsLoading();
            this.props.getItems();
        }
    }

    componentWillUnmount() {
        if(this.pubnub){
            this.pubnub.removeListener(this.pubnubListener);
            this.pubnub.unsubscribeAll();
        }    
    }

    initializePubNub = () => {
        const settings = this.props.globalState.settings;
        if(settings.pubnub_publish_key && settings.pubnub_subscribe_key && settings.hopscotch_id){
            if(!this.pubnub){
                this.pubnub = new PubNubReact({ 
                    publishKey: settings.pubnub_publish_key, 
                    subscribeKey: settings.pubnub_subscribe_key
                });
                this.pubnub.init(this);
                this.pubnub.addListener(this.pubnubListener); 
            }
    
            this.pubnub.unsubscribeAll();
            this.pubnub.subscribe({ 
                channels: [settings.hopscotch_id] 
            });
        }
    }

    playJingle = () => {
        try{
            let playPromise = jingle.play();
            if (playPromise !== null){
                playPromise.catch(() => jingle.play() );
            }
        }catch(err){
            console.log(`Caught error playing jingle: ${err}. Trying again...`);
            jingle.play();
        }
    }
    state = {
        isOpen: false
    }
  
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleDateChange = date => {
        this.props.setDate(date);
    }

    render(){
        
        const { settings, items /*, loading */} = this.props.globalState;
        const selectedDate = (settings && settings.date) ? new Date(settings.date) : new Date();
        const orderIds = [];
        let itemStatus;
        let numOrders = items.reduce((prevNumOrders, item) => {
                itemStatus = item.history.length > 0 ? item.history[item.history.length-1].status : null;
                if(
                    itemStatus !== STATE_ORDER_CANCELLED && 
                    itemStatus !== STATE_ORDER_CANCELLED_AFTER_COLLECTION &&
                    itemStatus !== STATE_ORDER_REJECTED &&
                    !orderIds.includes(item.order.id) 
                ){
                        orderIds.push(item.order.id);
                        return prevNumOrders+1;
                } else {
                    return prevNumOrders;
                }    
            }, 0
        );
        let excludesText = settings.showAllOrders ? 
        "Excludes cancelled and rejected orders. " : 
        "Excludes cancelled, rejected and completed orders, except those with messages unread by Hopscotch. ";
        return (
                <Navbar sticky="top" style={{backgroundColor: '#a32273'}} dark expand="sm" className="mb-5">
                    <Container>
                        <div style={{width: '25%', display: 'inline'}}>
                            <img style={{maxWidth: '100%', maxHeight: '100%'}} src={logo} alt="Hopscotch"/>
                        </div>
                        <span style={{color: 'white', width: '80%', textAlign: 'center'}}>
                            {settings.hopscotch_id ? "Mission Control" : "[Mission Control not configured]"}
                        </span>
                        {
                           /* loading ?
                            <span style={{width: '20%', textAlign: 'center'}}>
                                <ClipLoader
                                    sizeUnit={"px"}
                                    size={20}
                                    color={'#ffff'}
                                    loading={true}
                                />
                            </span> : */
                            <span title={excludesText+"A multi-restaurant order counts as 1 order."} style={{color: 'white', width: '20%', textAlign: 'center'}}>
                            {`${numOrders} ${settings.showAllOrders?'':'active'} order${numOrders!==1?"s":""}`}
                            </span>
                        }
                        

                        <DatePicker 
                            selected={selectedDate} 
                            onChange={this.handleDateChange} 
                            dateFormat="EEE, MMMM d"
                        />
                        
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <SettingsModal />
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    globalState: state.item
});

export default connect(mapStateToProps, { 
    addItems,
    updateItems,
    getItems, 
    setDate,
    setItemsLoading
} )(AppNavbar);