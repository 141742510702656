import axios from 'axios';
import { 
    ADD_ITEMS,
    UPDATE_ITEMS,
    GET_ITEMS, 
    DELETE_ITEM, 
    ITEMS_LOADING, 
    SAVE_SETTINGS,
    NO_ITEMS
} from './types';

export const confirmOrder = (confirmObj, callbackSuccess) => dispatch => {               
    
    axios
    .put(`/api/items/${confirmObj.item_id}`, {
        accepted: confirmObj.accepted,
        preparation_time: confirmObj.preparation_time,
        message: confirmObj.message 
    })
    .then(() => callbackSuccess(true))
    .catch(err => {
        console.log(err);
        callbackSuccess(false);
    });
};

export const cancelOrder = (cancelObj, callbackSuccess) => dispatch => {    
    axios
        .put(`/api/items/${cancelObj.item_id}`, {
            cancelled: cancelObj.cancelled
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const cancelTasks = (cancelObj, callbackSuccess) => dispatch => {    
    console.log("In cancelTasks");
    axios
        .put(`/api/items/${cancelObj.item_id}`, {
            cancelled: cancelObj.cancelled,
            cancelTasks: true
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const cancelOrderAfterCollection = (cancelObj, callbackSuccess) => dispatch => {    
    axios
        .put(`/api/items/${cancelObj.item_id}`, {
            cancelled_after_collection: cancelObj.cancelled
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const rejectOrder = (rejectObj, callbackSuccess) => dispatch => {    
    axios
        .put(`/api/items/${rejectObj.item_id}`, {
            rejected: rejectObj.rejected
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const sendEmail = (emailObj, callbackSuccess) => dispatch => {
    axios
        .put(`/api/items/${emailObj.item_id}`, {
            email: emailObj.email
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const verifyFirstTimeCustomer = (verifyObj, callbackSuccess) => dispatch => {
    axios
        .put(`/api/items/${verifyObj.item_id}`, {
            first_time_customer_verified: verifyObj.first_time_customer_verified
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const sendMessage = (item, callbackSuccess) => dispatch => {
    
    axios
        .put(`/api/items/${item.id}`, {
            message: item.message 
        })
        .then(() => callbackSuccess("send", true))
        .catch(err => {
            console.log(err)
            callbackSuccess("send", false);
        });
};

export const readMessages = (item, callbackSuccess) => dispatch => {
    axios
        .put(`/api/items/${item.id}`, {
            "messages.readByHopscotch": item.readByHopscotch 
        })
        .then(() => callbackSuccess("read", true))
        .catch(err => {
            console.log(err)
            callbackSuccess("read", false);
        });
};

export const getItems = () => dispatch => {
    const settings = JSON.parse(localStorage.getItem('settings')) || {};
    settings.date = settings.date || new Date();
    localStorage.setItem('settings', JSON.stringify(settings));
    
    if(settings && settings.hopscotch_id){  
        // Make sure that the Mission Control ID is valid before fetching items. Otherwise, 
        // dispatchers who have left the company will still be able to see order in MC
        axios.post('/api/settings', {
            identifier: settings.hopscotch_id
        }).then(res => 
            axios
            .post('/api/items', {
                date: settings.date,
                showAllOrders: settings.showAllOrders
            })
            .then(res => 
                dispatch({
                    type: GET_ITEMS,
                    payload: res.data
                })
            ).catch(err => console.log(err))
        ).catch(err => {
            console.log(err);
            dispatch({
                type: NO_ITEMS
            });
        });

        /*axios
            .post('/api/items', {
                date: settings.date,
                showAllOrders: settings.showAllOrders
            })
            .then(res => 
                dispatch({
                    type: GET_ITEMS,
                    payload: res.data
                })
            ).catch(err => console.log(err));*/
    } 
};

/*export const getItem = item_id => dispatch => {
    axios
        .get(`/api/items/${item_id}`)
        .then(res => 
            dispatch({
                type: GET_ITEM,
                payload: res.data
            })
        ).catch(err => console.log(err));
};*/

export const addItems = items => {
    return {
        type: ADD_ITEMS,
        payload: items
    };
};

export const updateItems = items => {
    return {
        type: UPDATE_ITEMS,
        payload: items
    };
};

export const saveSettings = (settings, callbackSuccess) => dispatch => {
    const savedSettings = JSON.parse(localStorage.getItem('settings')) || {};
    savedSettings.showAllOrders = settings.showAllOrders;
    if(settings.hopscotch_id){
        axios
        .post('/api/settings', {
            identifier: settings.hopscotch_id
        }).then(res => {
            savedSettings.hopscotch_id = settings.hopscotch_id; 
            savedSettings.pubnub_publish_key = res.data.pubnub_publish_key;
            savedSettings.pubnub_subscribe_key = res.data.pubnub_subscribe_key;
            
            localStorage.setItem('settings', JSON.stringify(savedSettings));
            dispatch({
                type: SAVE_SETTINGS,
                payload: savedSettings
            });
            callbackSuccess(true);
        }).catch(err => {
            callbackSuccess(false, err.response.status);
        });
    } else {
        localStorage.setItem('settings', JSON.stringify(savedSettings));
        dispatch({
            type: SAVE_SETTINGS,
            payload: savedSettings
        });
        callbackSuccess(true);
    }
    
};

export const setDate = date => dispatch => {
    const settings = JSON.parse(localStorage.getItem('settings')) || {};
    settings.date = date;
    localStorage.setItem('settings', JSON.stringify(settings));
    dispatch({
        type: SAVE_SETTINGS,
        payload: settings
    });
};

export const completePickup = (item, callbackSuccess) => dispatch => {
    
    axios
        .put(`/api/items/${item.item_id}`, {
            pickup_completed: item.pickup_completed,
            final_order_total: item.final_order_total
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const deleteItem = id => dispatch => {
    axios
        .put(`/api/items/${id}`, {
            deletePermanent: true 
        })
        .then(() => 
            dispatch({
                type: DELETE_ITEM,
                payload: id
            })
        ).catch(err => console.log(err));
};

export const switchRestaurant = (switchObj, callbackSuccess) => dispatch => {
    axios
        .put(`/api/items/${switchObj.item_id}`, {
            switched: {
                from: switchObj.from,
                to: switchObj.to,
                at: switchObj.at,
            } 
        })
        .then(() => callbackSuccess(true))
        .catch(err => {
            console.log(err);
            callbackSuccess(false);
        });
};

export const setItemsLoading = () => {
    const settings = JSON.parse(localStorage.getItem('settings')) || {};
    if(settings.hopscotch_id){
        return {
            type: ITEMS_LOADING
        };
    } 

    return {
        type: "None"
    };
};
