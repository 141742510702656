import { 
    ADD_ITEMS,
    UPDATE_ITEMS,
    GET_ITEMS, 
    ITEMS_LOADING,  
    SAVE_SETTINGS,
    NO_ITEMS
 } from '../actions/types'; 

const moment = require('moment');
const initialState = {
    items: [],
    loading: false,
    settings: JSON.parse(localStorage.getItem('settings')) || {date: new Date()} 
};

const TASK_STATUS_SUCCESSFUL = 2;
const ORDER_STATUS_REJECTED = 3;
const ORDER_STATUS_FULFILLED = 4;
const ORDER_STATUS_CANCELLED = 5;
const ORDER_STATUS_CANCELLED_AFTER_COLLECTION = 7;

export default function (state = initialState, action){
    switch(action.type) {
        case GET_ITEMS: 
            return {
                ...state,
                items: action.payload,
                loading: false
            };
        
        case ADD_ITEMS: 
            return {
                ...state,
                // Note: If MC is showing orders on a date in the past, we need to filter out new items that come in now
                // Sorting is necessary because a message may be applied to an order from the past
                items: [...action.payload, ...state.items].filter(filterFunc).sort(sortFunc),
                //items: [...action.payload, items_to_show].sort(sortFunc),
                loading: false
            };

        case UPDATE_ITEMS: 
            return {
                ...state,
                // Note: We need to filter here too, because we may need to remove an item from the state
                // That might happen, for example, if the item is only there because there's an unread message from a restaurant, and then the message is read
                items: state.items.map(item => action.payload.find(i => i._id === item._id) || item).filter(filterFunc),
                loading: false
            };

        case ITEMS_LOADING:
            return {
                ...state,
               // items: itemsOnSelectedDate,
               //items: items_to_show,
               items: [],
               loading: true
            };
        case SAVE_SETTINGS:
            return {
                ...state,
               // items: itemsOnSelectedDate,
               // items: items_to_show,
                settings: action.payload
            };

        case NO_ITEMS:
            return {
                ...state,
                items: [],
                loading: false
            };
        
        default: 
            return state;
    }

    function filterFunc(item){
        const mc_date = moment(new Date(state.settings.date));
        const start_of_date = mc_date.startOf("day").toDate();
        const end_of_date = mc_date.endOf("day").toDate();
        const start_of_past_period = mc_date.clone().subtract(7, 'days').startOf("day").toDate();
        return item.messages.some(m => m.sender !== "Hopscotch" && !m.readByHopscotch && new Date(m.sent) < end_of_date) ||
            (new Date(item.order.order_date) >= start_of_past_period && new Date(item.order.order_date) < start_of_date && !isItemComplete(item)) ||                              
            (new Date(item.order.order_date) >= start_of_date && new Date(item.order.order_date) < end_of_date &&  
            (state.settings.showAllOrders || !isItemComplete(item)));
    }

    function sortFunc(a, b){
        if (new Date(a.order.order_date) > new Date(b.order.order_date)) {
            return -1;
          }
          if (new Date(a.order.order_date) < new Date(b.order.order_date)) {
            return 1;
          }
          return 0;
    }
}

function isItemComplete(item){
    if(!item) return false;

    return (
        (item.delivery_task && item.delivery_task.job_status===TASK_STATUS_SUCCESSFUL) ||
        (item.order && [
            ORDER_STATUS_REJECTED, 
            ORDER_STATUS_FULFILLED, 
            ORDER_STATUS_CANCELLED, 
            ORDER_STATUS_CANCELLED_AFTER_COLLECTION
        ].includes(item.order.state)) ||
        item.cancelled ||
        item.cancelled_after_collection ||
        item.rejected ||
        item.pickup_completed
    );
}